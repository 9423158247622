import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const convertToIST = (dateString) =>{
    if(!dateString){
        return '';
    }
    // Get the time in IST (IST is GMT+5:30)
    const timeIST = dayjs(dateString).tz('Asia/Kolkata').format('HH:mm');
    return timeIST;
}

const customAirlinesName = (name) =>{
    if(name === "AKJ"){
        return "Akasa Airlines"
    }else if(name === "Fly Wex"){
        return "Air India Express"
    }
    return name
}

export {
    convertToIST,
    customAirlinesName
}
