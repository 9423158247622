import React from 'react';
import "./common.css"

const Footer = (props) =>{
    return(
        <div className='footer-div'>
            <p>Powered by CorePeelers. All rights reserved.</p>
        </div>
     )
}

export default Footer;