import { useState, useEffect } from "react";
import { requestCallPost } from "../../service-request/APIRequest";
import {
  isValidGSTIN,
  validateEmail,
  validateMobile,
  validatePassword,
} from "../../utils/UtilityFunctions";
import { useNavigate } from "react-router-dom";
import { baseURLForImage } from "../../config/baseURLPath";

const BLANK_LOGIN_PARAMS = {
  email: "",
  password: "",
};

const BLANK_SIGNUP_FIELDS = {
  first_name: "",
  last_name: "",
  mobile_number: "",
  password: "",
  hotel_name: "",
  hotel_address: "",
  gst_number: "",
  airport_id: {},
  original_file_name: "",
  logo_path_base64: "",
};

const BLANK_RESET_PASSWORD = {
  email: "",
  confirm_password: "",
  reset_password_key:""
};

const useAuth = (page) => {
  const navigate = useNavigate();
  const [loginParams, setLoginParams] = useState(BLANK_LOGIN_PARAMS);
  const [loginParamError, setLoginParamsError] = useState(null);

  const [signupParams, setSignupParams] = useState({...BLANK_SIGNUP_FIELDS, email: localStorage.getItem("email")});
  const [signupParamError, setSignupParamsError] = useState(null);

  const [airportList, setAirportList] = useState([]);
  const [registration, setRegistration] = useState(null);

  const [loginLoading, setLoginLoading] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);

  const [forgotPassword, setForgotPassword] = useState("");
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(null);

  const [resetParams, setResetParams] = useState(BLANK_RESET_PASSWORD);
  const [resetParamError, setResetParamError] = useState(null);
  const [reset, setReset] = useState(null);
  const [resetLoading, setResetLoading] = useState(false);
  useEffect(() => {
    if (page === "signup") {
      getAirportList();
    }
  }, []);

  const handleLoginParamChange = (name, value) => {
    if (loginParamError !== null) {
      setLoginParamsError(null);
    }
    setLoginParams({ ...loginParams, [name]: value });
  };

  const handleSignupParamChange = (name, value) => {
    if (signupParamError !== null) {
      setSignupParamsError(null);
    }
    setSignupParams({ ...signupParams, [name]: value });
  };

  const handleResetParamChange = (name, value) => {
    if (resetParamError !== null) {
      setResetParamError(null);
    }
    setResetParams({ ...resetParams, [name]: value });
  };

  const resetPasswordFunc = async () => {
    let req = {...resetParams};
    if (!validatePassword(req.password)) {
      setResetParamError({
        password:
          "Password must be 8 character long with 1 special character and one numeric.",
      });
      return;
    }else if(req.password !== req.confirm_password){
      setResetParamError({
        confirm_password:
          "Password not matched.",
      });
      return;
    }
    setResetLoading(true);
    const response = await requestCallPost("/customer/resetPasswordValidate", req);
    setResetLoading(false);
    if (response.status && response.data?.code === 200) {
      setResetParams(BLANK_RESET_PASSWORD)
      setReset({
        status: "success",
        message: "Password successfully updated.",
        code: 200,
      })
      setTimeout(()=>{
        navigate("/")
      }, 2000)
      return;
    } else if (response?.message?.data?.data) {
      setReset({
        status: "danger",
        message: response?.message?.data?.data,
        code: response?.message?.data?.code,
      });
      return;
    }
    setReset({
      status: "danger",
      message: "Something went wrong please try again.",
    });
    return false;
  };

  const forgotPasswordFunc = async () => {
    let req = { email: forgotPassword };
    setForgotPasswordLoading(true);
    const response = await requestCallPost("/customer/resetPassword", req);
    setForgotPasswordLoading(false);
    if (response.status && response.data?.code === 200) {
      setForgotPassword("")
      setForgotPasswordStatus({
        status: "success",
        message: "An email sent on your registered email.",
        code: 200,
      })
      return;
    } else if (response?.message?.data?.data) {
      setForgotPasswordStatus({
        status: "danger",
        message: response?.message?.data?.data,
        code: response?.message?.data?.code,
      });
      return;
    }
    setForgotPasswordStatus({
      status: "danger",
      message: "Something went wrong please try again.",
    });
    return false;
  };

  const verifyEmail = async (params) => {
    const response = await requestCallPost(`/customer/emailValidate`, params);
    if (response.status && response.data?.code === 200) {
      return true;
    }
    return { status: false, status_msg: response?.message?.data?.data };
  };

  const customerLogin = async (type) => {
    // Check validations
    let req = { ...loginParams };
    let url = "/customer/login";
    if (type === "signup") {
      if (!validateEmail(req.email)) {
        setLoginParamsError({ email: "Please enter a valid email address." });
        return;
      } else if (!validatePassword(req.password)) {
        setLoginParamsError({
          password:
            "Password must be 8 character long with 1 special character and one numeric.",
        });
        return;
      }
      req = {
        ...req,
        active_status: true
      }
      url = "/customer/signup";
      setSignupLoading(true);
    }else{
      setLoginLoading(true)
    }

    const response = await requestCallPost(url, req);
    setLoginLoading(false);
    setSignupLoading(false);
    if (response.status && response.data?.code === 200) {
      setLoginParams(BLANK_SIGNUP_FIELDS);
      if (type === "signup") {
        setRegistration({
          status: "success",
          message:
            "Registration successfully! we have sent you an email verification link on your email. Please check and continue through that.",
        });
        return 
      } else {
        setLoginParams(BLANK_SIGNUP_FIELDS);
        localStorage.setItem(
          "current_user_id",
          response.data?.data?.id
        );
        localStorage.setItem(
          "email",
          response.data?.data?.email
        );
        localStorage.setItem(
          "current_session_id",
          response.data?.data?.current_session_id
        );
        if(!response.data?.data?.active_status){
          navigate("/register");
          return
        }
        localStorage.setItem(
          "name",
          response?.data?.data?.customer_details?.first_name
        );
        localStorage.setItem(
          "hotel_name",
          response?.data?.data?.customer_details?.hotel_name
        );
        localStorage.setItem(
          "hotel_address",
          response?.data?.data?.customer_details?.hotel_address
        );
        // logo url
        let logoURL = baseURLForImage + response?.data?.data?.customer_details?.accessUrl;
        localStorage.setItem("logo_path", logoURL);
        navigate("/settings");
        return;
      }
    } else if (response?.message?.data?.data) {
      let msg = response?.message?.data?.data;
      if(response?.message?.data?.message === "Conflict"){
        msg = "Email already registered with us.";
      }
      setRegistration({
        status: "danger",
        message: msg,
        code: response?.message?.data?.code,
      });
      return;
    }
    setRegistration({
      status: "danger",
      message: "Something went wrong please try again.",
    });
    return false;
  };

  const forceLogin = async () => {
    // Check validations
    let req = { ...loginParams };
    setLoginLoading(true);
    const response = await requestCallPost("/customer/forceLogin", req);
    setLoginLoading(false);
    if (response.status && response.data?.code === 200) {
      setLoginParams(BLANK_SIGNUP_FIELDS);
      localStorage.setItem(
        "current_user_id",
        response.data?.data?.id
      );
      localStorage.setItem(
        "email",
        response.data?.data?.email
      );
      localStorage.setItem(
        "current_session_id",
        response.data?.data?.current_session_id
      );
      if(!response.data?.data?.active_status){
        navigate("/register");
        return
      }
      localStorage.setItem(
        "name",
        response?.data?.data?.customer_details?.first_name
      );
      localStorage.setItem(
        "hotel_name",
        response?.data?.data?.customer_details?.hotel_name
      );
      localStorage.setItem(
        "hotel_address",
        response?.data?.data?.customer_details?.hotel_address
      );
      let logoURL = baseURLForImage + response?.data?.data?.customer_details?.accessUrl;
      localStorage.setItem("logo_path", logoURL);
      navigate("/settings");
      return;
    } else if (response?.message?.data?.data) {
      setRegistration({
        status: "danger",
        message: response?.message?.data?.data,
        code: response?.message?.data?.code,
      });
      return;
    }
    setRegistration({
      status: "danger",
      message: "Something went wrong please try again.",
    });
    return false;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();

      // Event listener for when the file is successfully read
      reader.onloadend = () => {
        let fullBase64URL = reader.result;
        let logoPath = fullBase64URL?.split(",");
        setSignupParams({
          ...signupParams,
          logo_path_base64: logoPath[1],
          original_file_name: file.name,
        });
      };

      // Read the file as a data URL (Base64 string)
      reader.readAsDataURL(file);
    }
  };

  const customerSignup = async () => {
    // Check validations
    let req = { ...signupParams };
    if (req.first_name?.trim() === "") {
      setSignupParamsError({ first_name: "Please enter your first name." });
      return;
    } else if (req.last_name?.trim() === "") {
      setSignupParamsError({ last_name: "Please enter your last name." });
      return;
    } else if (!validateMobile(req.mobile_number)) {
      setSignupParamsError({
        mobile_number: "Please enter valid mobile number.",
      });
      return;
    } else if (req.hotel_name?.trim() === "") {
      setSignupParamsError({ hotel_name: "Please enter company name." });
      return;
    } else if (req.hotel_address?.trim() === "") {
      setSignupParamsError({ hotel_address: "Please enter address." });
      return;
    } else if (!isValidGSTIN(req.gst_number)) {
      setSignupParamsError({ gst_number: "Please enter your a valid GSTN." });
      return;
    } else if (req.airport_id?.id === undefined) {
      setSignupParamsError({ airport_id: "Please select airport." });
      return;
    } else if (req.original_file_name?.trim() === "") {
      setSignupParamsError({
        original_file_name: "Please select your logo file.",
      });
      return;
    }
    setSignupLoading(true);
    req = {
      ...req,
      customer_login_id: {
        id: localStorage.getItem("current_user_id")
      },
      email: undefined,
      password: undefined,
      active_status: undefined
    }

    const response = await requestCallPost("/customer/profile/updateProfile", req, localStorage.getItem("current_session_id"));
    setSignupLoading(false);
    if (response.status && response.data?.code === 200) {
      
      localStorage.setItem(
        "name",
        response?.data?.data?.first_name
      );
      localStorage.setItem(
        "hotel_name",
        response?.data?.data?.hotel_name
      );
      localStorage.setItem(
        "hotel_address",
        response?.data?.data?.hotel_address
      );
      let logoURL = baseURLForImage + response?.data?.data?.accessUrl;
      localStorage.setItem("logo_path", logoURL);
      setSignupParams(BLANK_SIGNUP_FIELDS);
      setRegistration({
        status: "success",
        message:
          "Your details successfully updated.",
      });
      setTimeout(()=>{
        navigate("/settings");
      }, 1000)
      return;
    } else if (response?.message?.status === 409) {
      setRegistration({
        status: "danger",
        message: "Email already registered with us.",
      });
      return;
    }
    setRegistration({
      status: "danger",
      message: "Something went wrong please try again.",
    });
    return false;
  };

  const getAirportList = async () => {
    let params = {};
    const response = await requestCallPost(
      "/airport/getAirport?page=0&size=1000",
      params
    );
    if (response.data?.data?.content) {
      setAirportList(response.data?.data?.content);
    }
    return false;
  };

  return {
    loginParams,
    handleLoginParamChange,
    loginParamError,
    verifyEmail,
    customerSignup,
    signupParams,
    setSignupParams,
    signupParamError,
    setSignupParamsError,
    handleSignupParamChange,
    airportList,
    registration,
    customerLogin,
    forceLogin,
    handleFileChange,
    loginLoading,
    signupLoading,
    forgotPasswordFunc,
    forgotPassword, 
    setForgotPassword,
    forgotPasswordStatus,
    forgotPasswordLoading,
    handleResetParamChange,
    resetParams,
    resetPasswordFunc,
    reset,
    resetParamError,
    resetLoading
  };
};

export default useAuth;
