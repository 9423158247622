import React from "react";
import "../Landing.css";

const DisplayTableHeader = ({ columns }) => {
  
  return (
    <div className="display-header-container">
      {columns?.map((item)=>{
        return(
           <div key={item.key} className="column">
            {item.label}
           </div> 
        )
      })}
    </div>
  );
};

export default DisplayTableHeader;
