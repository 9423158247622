import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screens/Auth/Login";
import FlightLandingPage from "./screens/Landing";
import Settings from "./screens/Settings";
import Signup from "./screens/Auth/Signup";
import ValidateEmail from "./screens/Auth/ValidateEmail";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import ResetPassword from "./screens/Auth/ResetPassword";

function App() {
  
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/validateEmail" element={<ValidateEmail />} />
          <Route path="/dashboard" element={<FlightLandingPage />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Routes>
    </Router>
  );
}

export default App;
