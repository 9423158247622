const logos = {
  "0J": require("../../../assets/airlines/0J.png"),
  "0N": require("../../../assets/airlines/0N.png"),
  "0T": require("../../../assets/airlines/0T.png"),
  "0V": require("../../../assets/airlines/0V.png"),
  "1I": require("../../../assets/airlines/1I.png"),
  "2A": require("../../../assets/airlines/2A.png"),
  "2C": require("../../../assets/airlines/2C.png"),
  "2D": require("../../../assets/airlines/2D.png"),
  "2F": require("../../../assets/airlines/2F.png"),
  "2H": require("../../../assets/airlines/2H.png"),
  "2I": require("../../../assets/airlines/2I.png"),
  "2J": require("../../../assets/airlines/2J.png"),
  "2K": require("../../../assets/airlines/2K.png"),
  "2L": require("../../../assets/airlines/2L.png"),
  "2M": require("../../../assets/airlines/2M.png"),
  "2N": require("../../../assets/airlines/2N.png"),
  "2O": require("../../../assets/airlines/2O.png"),
  "2P": require("../../../assets/airlines/2P.png"),
  "2Q": require("../../../assets/airlines/2Q.png"),
  "2R": require("../../../assets/airlines/2R.png"),
  "2S": require("../../../assets/airlines/2S.png"),
  "2T": require("../../../assets/airlines/2T.png"),
  "2U": require("../../../assets/airlines/2U.png"),
  "2V": require("../../../assets/airlines/2V.png"),
  "2W": require("../../../assets/airlines/2W.png"),
  "2Y": require("../../../assets/airlines/2Y.png"),
  "2Z": require("../../../assets/airlines/2Z.png"),
  "3A": require("../../../assets/airlines/3A.png"),
  "3C": require("../../../assets/airlines/3C.png"),
  "3D": require("../../../assets/airlines/3D.png"),
  "3E": require("../../../assets/airlines/3E.png"),
  "3F": require("../../../assets/airlines/3F.png"),
  "3G": require("../../../assets/airlines/3G.png"),
  "3H": require("../../../assets/airlines/3H.png"),
  "3J": require("../../../assets/airlines/3J.png"),
  "3K": require("../../../assets/airlines/3K.png"),
  "3L": require("../../../assets/airlines/3L.png"),
  "3M": require("../../../assets/airlines/3M.png"),
  "3N": require("../../../assets/airlines/3N.png"),
  "3O": require("../../../assets/airlines/3O.png"),
  "3P": require("../../../assets/airlines/3P.png"),
  "3R": require("../../../assets/airlines/3R.png"),
  "3S": require("../../../assets/airlines/3S.png"),
  "3T": require("../../../assets/airlines/3T.png"),
  "3U": require("../../../assets/airlines/3U.png"),
  "3V": require("../../../assets/airlines/3V.png"),
  "3W": require("../../../assets/airlines/3W.png"),
  "3X": require("../../../assets/airlines/3X.png"),
  "3Z": require("../../../assets/airlines/3Z.png"),
  "4A": require("../../../assets/airlines/4A.png"),
  "4B": require("../../../assets/airlines/4B.png"),
  "4C": require("../../../assets/airlines/4C.png"),
  "4E": require("../../../assets/airlines/4E.png"),
  "4F": require("../../../assets/airlines/4F.png"),
  "4G": require("../../../assets/airlines/4G.png"),
  "4I": require("../../../assets/airlines/4I.png"),
  "4K": require("../../../assets/airlines/4K.png"),
  "4L": require("../../../assets/airlines/4L.png"),
  "4M": require("../../../assets/airlines/4M.png"),
  "4N": require("../../../assets/airlines/4N.png"),
  "4O": require("../../../assets/airlines/4O.png"),
  "4S": require("../../../assets/airlines/4S.png"),
  "4T": require("../../../assets/airlines/4T.png"),
  "4W": require("../../../assets/airlines/4W.png"),
  "4Y": require("../../../assets/airlines/4Y.png"),
  "4Z": require("../../../assets/airlines/4Z.png"),
  "5A": require("../../../assets/airlines/5A.png"),
  "5B": require("../../../assets/airlines/5B.png"),
  "5C": require("../../../assets/airlines/5C.png"),
  "5D": require("../../../assets/airlines/5D.png"),
  "5F": require("../../../assets/airlines/5F.png"),
  "5G": require("../../../assets/airlines/5G.png"),
  "5H": require("../../../assets/airlines/5H.png"),
  "5I": require("../../../assets/airlines/5I.png"),
  "5J": require("../../../assets/airlines/5J.png"),
  "5K": require("../../../assets/airlines/5K.png"),
  "5L": require("../../../assets/airlines/5L.png"),
  "5M": require("../../../assets/airlines/5M.png"),
  "5N": require("../../../assets/airlines/5N.png"),
  "5O": require("../../../assets/airlines/5O.png"),
  "5P": require("../../../assets/airlines/5P.png"),
  "5R": require("../../../assets/airlines/5R.png"),
  "5T": require("../../../assets/airlines/5T.png"),
  "5U": require("../../../assets/airlines/5U.png"),
  "5V": require("../../../assets/airlines/5V.png"),
  "5W": require("../../../assets/airlines/5W.png"),
  "5X": require("../../../assets/airlines/5X.png"),
  "5Y": require("../../../assets/airlines/5Y.png"),
  "5Z": require("../../../assets/airlines/5Z.png"),
  "6A": require("../../../assets/airlines/6A.png"),
  "6B": require("../../../assets/airlines/6B.png"),
  "6C": require("../../../assets/airlines/6C.png"),
  "6D": require("../../../assets/airlines/6D.png"),
  "6E": require("../../../assets/airlines/6E.png"),
  "6F": require("../../../assets/airlines/6F.png"),
  "6G": require("../../../assets/airlines/6G.png"),
  "6H": require("../../../assets/airlines/6H.png"),
  "6I": require("../../../assets/airlines/6I.png"),
  "6J": require("../../../assets/airlines/6J.png"),
  "6K": require("../../../assets/airlines/6K.png"),
  "6L": require("../../../assets/airlines/6L.png"),
  "6M": require("../../../assets/airlines/6M.png"),
  "6N": require("../../../assets/airlines/6N.png"),
  "6O": require("../../../assets/airlines/6O.png"),
  "6P": require("../../../assets/airlines/6P.png"),
  "6R": require("../../../assets/airlines/6R.png"),
  "6V": require("../../../assets/airlines/6V.png"),
  "6Y": require("../../../assets/airlines/6Y.png"),
  "7A": require("../../../assets/airlines/7A.png"),
  "7C": require("../../../assets/airlines/7C.png"),
  "7D": require("../../../assets/airlines/7D.png"),
  "7E": require("../../../assets/airlines/7E.png"),
  "7G": require("../../../assets/airlines/7G.png"),
  "7H": require("../../../assets/airlines/7H.png"),
  "7I": require("../../../assets/airlines/7I.png"),
  "7J": require("../../../assets/airlines/7J.png"),
  "7K": require("../../../assets/airlines/7K.png"),
  "7L": require("../../../assets/airlines/7L.png"),
  "7M": require("../../../assets/airlines/7M.png"),
  "7O": require("../../../assets/airlines/7O.png"),
  "7P": require("../../../assets/airlines/7P.png"),
  "7Q": require("../../../assets/airlines/7Q.png"),
  "7R": require("../../../assets/airlines/7R.png"),
  "7S": require("../../../assets/airlines/7S.png"),
  "7T": require("../../../assets/airlines/7T.png"),
  "7U": require("../../../assets/airlines/7U.png"),
  "7V": require("../../../assets/airlines/7V.png"),
  "7W": require("../../../assets/airlines/7W.png"),
  "7Y": require("../../../assets/airlines/7Y.png"),
  "7Z": require("../../../assets/airlines/7Z.png"),
  "8B": require("../../../assets/airlines/8B.png"),
  "8C": require("../../../assets/airlines/8C.png"),
  "8D": require("../../../assets/airlines/8D.png"),
  "8E": require("../../../assets/airlines/8E.png"),
  "8F": require("../../../assets/airlines/8F.png"),
  "8G": require("../../../assets/airlines/8G.png"),
  "8H": require("../../../assets/airlines/8H.png"),
  "8J": require("../../../assets/airlines/8J.png"),
  "8K": require("../../../assets/airlines/8K.png"),
  "8L": require("../../../assets/airlines/8L.png"),
  "8M": require("../../../assets/airlines/8M.png"),
  "8N": require("../../../assets/airlines/8N.png"),
  "8R": require("../../../assets/airlines/8R.png"),
  "8S": require("../../../assets/airlines/8S.png"),
  "8T": require("../../../assets/airlines/8T.png"),
  "8U": require("../../../assets/airlines/8U.png"),
  "8V": require("../../../assets/airlines/8V.png"),
  "8W": require("../../../assets/airlines/8W.png"),
  "8Z": require("../../../assets/airlines/8Z.png"),
  "9B": require("../../../assets/airlines/9B.png"),
  "9C": require("../../../assets/airlines/9C.png"),
  "9D": require("../../../assets/airlines/9D.png"),
  "9E": require("../../../assets/airlines/9E.png"),
  "9F": require("../../../assets/airlines/9F.png"),
  "9H": require("../../../assets/airlines/9H.png"),
  "9I": require("../../../assets/airlines/9I.png"),
  "9J": require("../../../assets/airlines/9J.png"),
  "9K": require("../../../assets/airlines/9K.png"),
  "9L": require("../../../assets/airlines/9L.png"),
  "9M": require("../../../assets/airlines/9M.png"),
  "9N": require("../../../assets/airlines/9N.png"),
  "9O": require("../../../assets/airlines/9O.png"),
  "9P": require("../../../assets/airlines/9P.png"),
  "9Q": require("../../../assets/airlines/9Q.png"),
  "9R": require("../../../assets/airlines/9R.png"),
  "9S": require("../../../assets/airlines/9S.png"),
  "9T": require("../../../assets/airlines/9T.png"),
  "9V": require("../../../assets/airlines/9V.png"),
  "9X": require("../../../assets/airlines/9X.png"),
  "9Y": require("../../../assets/airlines/9Y.png"),
  A0: require("../../../assets/airlines/A0.png"),
  A1: require("../../../assets/airlines/A1.png"),
  A2: require("../../../assets/airlines/A2.png"),
  A3: require("../../../assets/airlines/A3.png"),
  A4: require("../../../assets/airlines/A4.png"),
  A5: require("../../../assets/airlines/A5.png"),
  A6: require("../../../assets/airlines/A6.png"),
  A7: require("../../../assets/airlines/A7.png"),
  A8: require("../../../assets/airlines/A8.png"),
  A9: require("../../../assets/airlines/A9.png"),
  AA: require("../../../assets/airlines/AA.png"),
  AC: require("../../../assets/airlines/AC.png"),
  AD: require("../../../assets/airlines/AD.png"),
  AE: require("../../../assets/airlines/AE.png"),
  AF: require("../../../assets/airlines/AF.png"),
  AG: require("../../../assets/airlines/AG.png"),
  AH: require("../../../assets/airlines/AH.png"),
  AI: require("../../../assets/airlines/AI.png"),
  AJ: require("../../../assets/airlines/AJ.png"),
  AK: require("../../../assets/airlines/AK.png"),
  AM: require("../../../assets/airlines/AM.png"),
  AN: require("../../../assets/airlines/AN.png"),
  AO: require("../../../assets/airlines/AO.png"),
  AP: require("../../../assets/airlines/AP.png"),
  AQ: require("../../../assets/airlines/AQ.png"),
  AR: require("../../../assets/airlines/AR.png"),
  AS: require("../../../assets/airlines/AS.png"),
  AT: require("../../../assets/airlines/AT.png"),
  AV: require("../../../assets/airlines/AV.png"),
  AW: require("../../../assets/airlines/AW.png"),
  AY: require("../../../assets/airlines/AY.png"),
  B0: require("../../../assets/airlines/B0.png"),
  B2: require("../../../assets/airlines/B2.png"),
  B3: require("../../../assets/airlines/B3.png"),
  B4: require("../../../assets/airlines/B4.png"),
  B6: require("../../../assets/airlines/B6.png"),
  B7: require("../../../assets/airlines/B7.png"),
  B9: require("../../../assets/airlines/B9.png"),
  BA: require("../../../assets/airlines/BA.png"),
  BB: require("../../../assets/airlines/BB.png"),
  BC: require("../../../assets/airlines/BC.png"),
  BD: require("../../../assets/airlines/BD.png"),
  BE: require("../../../assets/airlines/BE.png"),
  BF: require("../../../assets/airlines/BF.png"),
  BG: require("../../../assets/airlines/BG.png"),
  BI: require("../../../assets/airlines/BI.png"),
  BJ: require("../../../assets/airlines/BJ.png"),
  BK: require("../../../assets/airlines/BK.png"),
  BL: require("../../../assets/airlines/BL.png"),
  BM: require("../../../assets/airlines/BM.png"),
  BP: require("../../../assets/airlines/BP.png"),
  BQ: require("../../../assets/airlines/BQ.png"),
  BR: require("../../../assets/airlines/BR.png"),
  BS: require("../../../assets/airlines/BS.png"),
  BT: require("../../../assets/airlines/BT.png"),
  BU: require("../../../assets/airlines/BU.png"),
  BV: require("../../../assets/airlines/BV.png"),
  BW: require("../../../assets/airlines/BW.png"),
  BX: require("../../../assets/airlines/BX.png"),
  BY: require("../../../assets/airlines/BY.png"),
  BZ: require("../../../assets/airlines/BZ.png"),
  C0: require("../../../assets/airlines/C0.png"),
  C3: require("../../../assets/airlines/C3.png"),
  C4: require("../../../assets/airlines/C4.png"),
  C5: require("../../../assets/airlines/C5.png"),
  C6: require("../../../assets/airlines/C6.png"),
  C7: require("../../../assets/airlines/C7.png"),
  C8: require("../../../assets/airlines/C8.png"),
  CA: require("../../../assets/airlines/CA.png"),
  CC: require("../../../assets/airlines/CC.png"),
  CD: require("../../../assets/airlines/CD.png"),
  CE: require("../../../assets/airlines/CE.png"),
  CF: require("../../../assets/airlines/CF.png"),
  CG: require("../../../assets/airlines/CG.png"),
  CH: require("../../../assets/airlines/CH.png"),
  CI: require("../../../assets/airlines/CI.png"),
  CJ: require("../../../assets/airlines/CJ.png"),
  CK: require("../../../assets/airlines/CK.png"),
  CL: require("../../../assets/airlines/CL.png"),
  CM: require("../../../assets/airlines/CM.png"),
  CN: require("../../../assets/airlines/CN.png"),
  CO: require("../../../assets/airlines/CO.png"),
  CP: require("../../../assets/airlines/CP.png"),
  CQ: require("../../../assets/airlines/CQ.png"),
  CU: require("../../../assets/airlines/CU.png"),
  CV: require("../../../assets/airlines/CV.png"),
  CW: require("../../../assets/airlines/CW.png"),
  CX: require("../../../assets/airlines/CX.png"),
  CY: require("../../../assets/airlines/CY.png"),
  CZ: require("../../../assets/airlines/CZ.png"),
  D0: require("../../../assets/airlines/D0.png"),
  D2: require("../../../assets/airlines/D2.png"),
  D3: require("../../../assets/airlines/D3.png"),
  D4: require("../../../assets/airlines/D4.png"),
  D5: require("../../../assets/airlines/D5.png"),
  D6: require("../../../assets/airlines/D6.png"),
  D7: require("../../../assets/airlines/D7.png"),
  D8: require("../../../assets/airlines/D8.png"),
  D9: require("../../../assets/airlines/D9.png"),
  DD: require("../../../assets/airlines/DD.png"),
  DE: require("../../../assets/airlines/DE.png"),
  DG: require("../../../assets/airlines/DG.png"),
  DH: require("../../../assets/airlines/DH.png"),
  DI: require("../../../assets/airlines/DI.png"),
  DJ: require("../../../assets/airlines/DJ.png"),
  DK: require("../../../assets/airlines/DK.png"),
  DL: require("../../../assets/airlines/DL.png"),
  DM: require("../../../assets/airlines/DM.png"),
  DN: require("../../../assets/airlines/DN.png"),
  DO: require("../../../assets/airlines/DO.png"),
  DP: require("../../../assets/airlines/DP.png"),
  DQ: require("../../../assets/airlines/DQ.png"),
  DR: require("../../../assets/airlines/DR.png"),
  DS: require("../../../assets/airlines/DS.png"),
  DT: require("../../../assets/airlines/DT.png"),
  DU: require("../../../assets/airlines/DU.png"),
  DV: require("../../../assets/airlines/DV.png"),
  DX: require("../../../assets/airlines/DX.png"),
  DY: require("../../../assets/airlines/DY.png"),
  DZ: require("../../../assets/airlines/DZ.png"),
  E2: require("../../../assets/airlines/E2.png"),
  E4: require("../../../assets/airlines/E4.png"),
  E5: require("../../../assets/airlines/E5.png"),
  E6: require("../../../assets/airlines/E6.png"),
  E7: require("../../../assets/airlines/E7.png"),
  E8: require("../../../assets/airlines/E8.png"),
  E9: require("../../../assets/airlines/E9.png"),
  EB: require("../../../assets/airlines/EB.png"),
  EC: require("../../../assets/airlines/EC.png"),
  ED: require("../../../assets/airlines/ED.png"),
  EE: require("../../../assets/airlines/EE.png"),
  EF: require("../../../assets/airlines/EF.png"),
  EG: require("../../../assets/airlines/EG.png"),
  EH: require("../../../assets/airlines/EH.png"),
  EI: require("../../../assets/airlines/EI.png"),
  EJ: require("../../../assets/airlines/EJ.png"),
  EK: require("../../../assets/airlines/EK.png"),
  EM: require("../../../assets/airlines/EM.png"),
  EN: require("../../../assets/airlines/EN.png"),
  EO: require("../../../assets/airlines/EO.png"),
  EP: require("../../../assets/airlines/EP.png"),
  EQ: require("../../../assets/airlines/EQ.png"),
  ER: require("../../../assets/airlines/ER.png"),
  ES: require("../../../assets/airlines/ES.png"),
  ET: require("../../../assets/airlines/ET.png"),
  EU: require("../../../assets/airlines/EU.png"),
  EW: require("../../../assets/airlines/EW.png"),
  EX: require("../../../assets/airlines/EX.png"),
  EY: require("../../../assets/airlines/EY.png"),
  EZ: require("../../../assets/airlines/EZ.png"),
  F2: require("../../../assets/airlines/F2.png"),
  F3: require("../../../assets/airlines/F3.png"),
  F4: require("../../../assets/airlines/F4.png"),
  F5: require("../../../assets/airlines/F5.png"),
  F6: require("../../../assets/airlines/F6.png"),
  F7: require("../../../assets/airlines/F7.png"),
  F8: require("../../../assets/airlines/F8.png"),
  F9: require("../../../assets/airlines/F9.png"),
  FA: require("../../../assets/airlines/FA.png"),
  FB: require("../../../assets/airlines/FB.png"),
  FC: require("../../../assets/airlines/FC.png"),
  FD: require("../../../assets/airlines/FD.png"),
  FE: require("../../../assets/airlines/FE.png"),
  FG: require("../../../assets/airlines/FG.png"),
  FH: require("../../../assets/airlines/FH.png"),
  FI: require("../../../assets/airlines/FI.png"),
  FJ: require("../../../assets/airlines/FJ.png"),
  FK: require("../../../assets/airlines/FK.png"),
  FL: require("../../../assets/airlines/FL.png"),
  FM: require("../../../assets/airlines/FM.png"),
  FN: require("../../../assets/airlines/FN.png"),
  FO: require("../../../assets/airlines/FO.png"),
  FP: require("../../../assets/airlines/FP.png"),
  FQ: require("../../../assets/airlines/FQ.png"),
  FR: require("../../../assets/airlines/FR.png"),
  FS: require("../../../assets/airlines/FS.png"),
  FT: require("../../../assets/airlines/FT.png"),
  FU: require("../../../assets/airlines/FU.png"),
  FV: require("../../../assets/airlines/FV.png"),
  FW: require("../../../assets/airlines/FW.png"),
  FX: require("../../../assets/airlines/FX.png"),
  FY: require("../../../assets/airlines/FY.png"),
  FZ: require("../../../assets/airlines/FZ.png"),
  G2: require("../../../assets/airlines/G2.png"),
  G3: require("../../../assets/airlines/G3.png"),
  G4: require("../../../assets/airlines/G4.png"),
  G7: require("../../../assets/airlines/G7.png"),
  G9: require("../../../assets/airlines/G9.png"),
  GA: require("../../../assets/airlines/GA.png"),
  GB: require("../../../assets/airlines/GB.png"),
  GC: require("../../../assets/airlines/GC.png"),
  GD: require("../../../assets/airlines/GD.png"),
  GE: require("../../../assets/airlines/GE.png"),
  GF: require("../../../assets/airlines/GF.png"),
  GH: require("../../../assets/airlines/GH.png"),
  GI: require("../../../assets/airlines/GI.png"),
  GJ: require("../../../assets/airlines/GJ.png"),
  GK: require("../../../assets/airlines/GK.png"),
  GL: require("../../../assets/airlines/GL.png"),
  GM: require("../../../assets/airlines/GM.png"),
  GO: require("../../../assets/airlines/GO.png"),
  GP: require("../../../assets/airlines/GP.png"),
  GQ: require("../../../assets/airlines/GQ.png"),
  GR: require("../../../assets/airlines/GR.png"),
  GS: require("../../../assets/airlines/GS.png"),
  GT: require("../../../assets/airlines/GT.png"),
  GU: require("../../../assets/airlines/GU.png"),
  GV: require("../../../assets/airlines/GV.png"),
  GW: require("../../../assets/airlines/GW.png"),
  GX: require("../../../assets/airlines/GX.png"),
  GY: require("../../../assets/airlines/GY.png"),
  GZ: require("../../../assets/airlines/GZ.png"),
  H1: require("../../../assets/airlines/H1.png"),
  H2: require("../../../assets/airlines/H2.png"),
  H4: require("../../../assets/airlines/H4.png"),
  H5: require("../../../assets/airlines/H5.png"),
  H6: require("../../../assets/airlines/H6.png"),
  H7: require("../../../assets/airlines/H7.png"),
  H8: require("../../../assets/airlines/H8.png"),
  H9: require("../../../assets/airlines/H9.png"),
  HA: require("../../../assets/airlines/HA.png"),
  HB: require("../../../assets/airlines/HB.png"),
  HC: require("../../../assets/airlines/HC.png"),
  HD: require("../../../assets/airlines/HD.png"),
  HF: require("../../../assets/airlines/HF.png"),
  HH: require("../../../assets/airlines/HH.png"),
  HJ: require("../../../assets/airlines/HJ.png"),
  HK: require("../../../assets/airlines/HK.png"),
  HM: require("../../../assets/airlines/HM.png"),
  HN: require("../../../assets/airlines/HN.png"),
  HO: require("../../../assets/airlines/HO.png"),
  HP: require("../../../assets/airlines/HP.png"),
  HQ: require("../../../assets/airlines/HQ.png"),
  HR: require("../../../assets/airlines/HR.png"),
  HS: require("../../../assets/airlines/HS.png"),
  HT: require("../../../assets/airlines/HT.png"),
  HU: require("../../../assets/airlines/HU.png"),
  HV: require("../../../assets/airlines/HV.png"),
  HW: require("../../../assets/airlines/HW.png"),
  HX: require("../../../assets/airlines/HX.png"),
  HY: require("../../../assets/airlines/HY.png"),
  HZ: require("../../../assets/airlines/HZ.png"),
  I2: require("../../../assets/airlines/I2.png"),
  I4: require("../../../assets/airlines/I4.png"),
  I6: require("../../../assets/airlines/I6.png"),
  I7: require("../../../assets/airlines/I7.png"),
  I8: require("../../../assets/airlines/I8.png"),
  I9: require("../../../assets/airlines/I9.png"),
  IA: require("../../../assets/airlines/IA.png"),
  IB: require("../../../assets/airlines/IB.png"),
  IC: require("../../../assets/airlines/IC.png"),
  ID: require("../../../assets/airlines/ID.png"),
  IE: require("../../../assets/airlines/IE.png"),
  IF: require("../../../assets/airlines/IF.png"),
  IH: require("../../../assets/airlines/IH.png"),
  II: require("../../../assets/airlines/II.png"),
  IJ: require("../../../assets/airlines/IJ.png"),
  IK: require("../../../assets/airlines/IK.png"),
  IL: require("../../../assets/airlines/IL.png"),
  IM: require("../../../assets/airlines/IM.png"),
  IN: require("../../../assets/airlines/IN.png"),
  IO: require("../../../assets/airlines/IO.png"),
  IP: require("../../../assets/airlines/IP.png"),
  IQ: require("../../../assets/airlines/IQ.png"),
  IR: require("../../../assets/airlines/IR.png"),
  IS: require("../../../assets/airlines/IS.png"),
  IT: require("../../../assets/airlines/IT.png"),
  IU: require("../../../assets/airlines/IU.png"),
  IV: require("../../../assets/airlines/IV.png"),
  IW: require("../../../assets/airlines/IW.png"),
  IX: require("../../../assets/airlines/IX.png"),
  IY: require("../../../assets/airlines/IY.png"),
  IZ: require("../../../assets/airlines/IZ.png"),
  J0: require("../../../assets/airlines/J0.png"),
  J2: require("../../../assets/airlines/J2.png"),
  J3: require("../../../assets/airlines/J3.png"),
  J4: require("../../../assets/airlines/J4.png"),
  J5: require("../../../assets/airlines/J5.png"),
  J6: require("../../../assets/airlines/J6.png"),
  J7: require("../../../assets/airlines/J7.png"),
  J8: require("../../../assets/airlines/J8.png"),
  J9: require("../../../assets/airlines/J9.png"),
  JA: require("../../../assets/airlines/JA.png"),
  JB: require("../../../assets/airlines/JB.png"),
  JC: require("../../../assets/airlines/JC.png"),
  JD: require("../../../assets/airlines/JD.png"),
  JE: require("../../../assets/airlines/JE.png"),
  JG: require("../../../assets/airlines/JG.png"),
  JH: require("../../../assets/airlines/JH.png"),
  JI: require("../../../assets/airlines/JI.png"),
  JJ: require("../../../assets/airlines/JJ.png"),
  JL: require("../../../assets/airlines/JL.png"),
  JM: require("../../../assets/airlines/JM.png"),
  JO: require("../../../assets/airlines/JO.png"),
  JP: require("../../../assets/airlines/JP.png"),
  JQ: require("../../../assets/airlines/JQ.png"),
  JR: require("../../../assets/airlines/JR.png"),
  JS: require("../../../assets/airlines/JS.png"),
  JT: require("../../../assets/airlines/JT.png"),
  JU: require("../../../assets/airlines/JU.png"),
  JV: require("../../../assets/airlines/JV.png"),
  JX: require("../../../assets/airlines/JX.png"),
  JY: require("../../../assets/airlines/JY.png"),
  JZ: require("../../../assets/airlines/JZ.png"),
  K1: require("../../../assets/airlines/K1.png"),
  K3: require("../../../assets/airlines/K3.png"),
  K4: require("../../../assets/airlines/K4.png"),
  K6: require("../../../assets/airlines/K6.png"),
  K7: require("../../../assets/airlines/K7.png"),
  K8: require("../../../assets/airlines/K8.png"),
  K9: require("../../../assets/airlines/K9.png"),
  KA: require("../../../assets/airlines/KA.png"),
  KB: require("../../../assets/airlines/KB.png"),
  KC: require("../../../assets/airlines/KC.png"),
  KD: require("../../../assets/airlines/KD.png"),
  KE: require("../../../assets/airlines/KE.png"),
  KF: require("../../../assets/airlines/KF.png"),
  KG: require("../../../assets/airlines/KG.png"),
  KH: require("../../../assets/airlines/KH.png"),
  KI: require("../../../assets/airlines/KI.png"),
  KJ: require("../../../assets/airlines/KJ.png"),
  KK: require("../../../assets/airlines/KK.png"),
  KL: require("../../../assets/airlines/KL.png"),
  KM: require("../../../assets/airlines/KM.png"),
  KN: require("../../../assets/airlines/KN.png"),
  KO: require("../../../assets/airlines/KO.png"),
  KP: require("../../../assets/airlines/KP.png"),
  KQ: require("../../../assets/airlines/KQ.png"),
  KR: require("../../../assets/airlines/KR.png"),
  KS: require("../../../assets/airlines/KS.png"),
  KT: require("../../../assets/airlines/KT.png"),
  KU: require("../../../assets/airlines/KU.png"),
  KV: require("../../../assets/airlines/KV.png"),
  KW: require("../../../assets/airlines/KW.png"),
  KX: require("../../../assets/airlines/KX.png"),
  KY: require("../../../assets/airlines/KY.png"),
  KZ: require("../../../assets/airlines/KZ.png"),
  L2: require("../../../assets/airlines/L2.png"),
  L3: require("../../../assets/airlines/L3.png"),
  L5: require("../../../assets/airlines/L5.png"),
  L6: require("../../../assets/airlines/L6.png"),
  L7: require("../../../assets/airlines/L7.png"),
  L8: require("../../../assets/airlines/L8.png"),
  L9: require("../../../assets/airlines/L9.png"),
  LA: require("../../../assets/airlines/LA.png"),
  LC: require("../../../assets/airlines/LC.png"),
  LD: require("../../../assets/airlines/LD.png"),
  LF: require("../../../assets/airlines/LF.png"),
  LG: require("../../../assets/airlines/LG.png"),
  LH: require("../../../assets/airlines/LH.png"),
  LJ: require("../../../assets/airlines/LJ.png"),
  LK: require("../../../assets/airlines/LK.png"),
  LM: require("../../../assets/airlines/LM.png"),
  LN: require("../../../assets/airlines/LN.png"),
  LO: require("../../../assets/airlines/LO.png"),
  LP: require("../../../assets/airlines/LP.png"),
  LR: require("../../../assets/airlines/LR.png"),
  LS: require("../../../assets/airlines/LS.png"),
  LT: require("../../../assets/airlines/LT.png"),
  LU: require("../../../assets/airlines/LU.png"),
  LW: require("../../../assets/airlines/LW.png"),
  LX: require("../../../assets/airlines/LX.png"),
  LY: require("../../../assets/airlines/LY.png"),
  LZ: require("../../../assets/airlines/LZ.png"),
  M0: require("../../../assets/airlines/M0.png"),
  M2: require("../../../assets/airlines/M2.png"),
  M3: require("../../../assets/airlines/M3.png"),
  M4: require("../../../assets/airlines/M4.png"),
  M5: require("../../../assets/airlines/M5.png"),
  M6: require("../../../assets/airlines/M6.png"),
  M7: require("../../../assets/airlines/M7.png"),
  M9: require("../../../assets/airlines/M9.png"),
  MD: require("../../../assets/airlines/MD.png"),
  ME: require("../../../assets/airlines/ME.png"),
  MF: require("../../../assets/airlines/MF.png"),
  MG: require("../../../assets/airlines/MG.png"),
  MH: require("../../../assets/airlines/MH.png"),
  MI: require("../../../assets/airlines/MI.png"),
  MJ: require("../../../assets/airlines/MJ.png"),
  MK: require("../../../assets/airlines/MK.png"),
  ML: require("../../../assets/airlines/ML.png"),
  MM: require("../../../assets/airlines/MM.png"),
  MO: require("../../../assets/airlines/MO.png"),
  MP: require("../../../assets/airlines/MP.png"),
  MQ: require("../../../assets/airlines/MQ.png"),
  MR: require("../../../assets/airlines/MR.png"),
  MS: require("../../../assets/airlines/MS.png"),
  MU: require("../../../assets/airlines/MU.png"),
  MV: require("../../../assets/airlines/MV.png"),
  MX: require("../../../assets/airlines/MX.png"),
  MY: require("../../../assets/airlines/MY.png"),
  MZ: require("../../../assets/airlines/MZ.png"),
  N0: require("../../../assets/airlines/N0.png"),
  N2: require("../../../assets/airlines/N2.png"),
  N3: require("../../../assets/airlines/N3.png"),
  N4: require("../../../assets/airlines/N4.png"),
  N5: require("../../../assets/airlines/N5.png"),
  N7: require("../../../assets/airlines/N7.png"),
  N8: require("../../../assets/airlines/N8.png"),
  N9: require("../../../assets/airlines/N9.png"),
  NB: require("../../../assets/airlines/NB.png"),
  NC: require("../../../assets/airlines/NC.png"),
  ND: require("../../../assets/airlines/ND.png"),
  NE: require("../../../assets/airlines/NE.png"),
  NF: require("../../../assets/airlines/NF.png"),
  NG: require("../../../assets/airlines/NG.png"),
  NH: require("../../../assets/airlines/NH.png"),
  NI: require("../../../assets/airlines/NI.png"),
  NJ: require("../../../assets/airlines/NJ.png"),
  NK: require("../../../assets/airlines/NK.png"),
  NL: require("../../../assets/airlines/NL.png"),
  NM: require("../../../assets/airlines/NM.png"),
  NN: require("../../../assets/airlines/NN.png"),
  NO: require("../../../assets/airlines/NO.png"),
  NP: require("../../../assets/airlines/NP.png"),
  NQ: require("../../../assets/airlines/NQ.png"),
  NR: require("../../../assets/airlines/NR.png"),
  NS: require("../../../assets/airlines/NS.png"),
  NT: require("../../../assets/airlines/NT.png"),
  NU: require("../../../assets/airlines/NU.png"),
  NV: require("../../../assets/airlines/NV.png"),
  NX: require("../../../assets/airlines/NX.png"),
  NZ: require("../../../assets/airlines/NZ.png"),
  O3: require("../../../assets/airlines/O3.png"),
  O4: require("../../../assets/airlines/O4.png"),
  OA: require("../../../assets/airlines/OA.png"),
  OB: require("../../../assets/airlines/OB.png"),
  OC: require("../../../assets/airlines/OC.png"),
  OD: require("../../../assets/airlines/OD.png"),
  OE: require("../../../assets/airlines/OE.png"),
  OF: require("../../../assets/airlines/OF.png"),
  OG: require("../../../assets/airlines/OG.png"),
  OH: require("../../../assets/airlines/OH.png"),
  OI: require("../../../assets/airlines/OI.png"),
  OJ: require("../../../assets/airlines/OJ.png"),
  OL: require("../../../assets/airlines/OL.png"),
  OM: require("../../../assets/airlines/OM.png"),
  ON: require("../../../assets/airlines/ON.png"),
  OO: require("../../../assets/airlines/OO.png"),
  OP: require("../../../assets/airlines/OP.png"),
  OQ: require("../../../assets/airlines/OQ.png"),
  OR: require("../../../assets/airlines/OR.png"),
  OS: require("../../../assets/airlines/OS.png"),
  OU: require("../../../assets/airlines/OU.png"),
  OV: require("../../../assets/airlines/OV.png"),
  OW: require("../../../assets/airlines/OW.png"),
  OY: require("../../../assets/airlines/OY.png"),
  OZ: require("../../../assets/airlines/OZ.png"),
  P0: require("../../../assets/airlines/P0.png"),
  P1: require("../../../assets/airlines/P1.png"),
  P2: require("../../../assets/airlines/P2.png"),
  P3: require("../../../assets/airlines/P3.png"),
  P4: require("../../../assets/airlines/P4.png"),
  P5: require("../../../assets/airlines/P5.png"),
  P6: require("../../../assets/airlines/P6.png"),
  P7: require("../../../assets/airlines/P7.png"),
  P8: require("../../../assets/airlines/P8.png"),
  P9: require("../../../assets/airlines/P9.png"),
  PA: require("../../../assets/airlines/PA.png"),
  PB: require("../../../assets/airlines/PB.png"),
  PC: require("../../../assets/airlines/PC.png"),
  PD: require("../../../assets/airlines/PD.png"),
  PE: require("../../../assets/airlines/PE.png"),
  PF: require("../../../assets/airlines/PF.png"),
  PG: require("../../../assets/airlines/PG.png"),
  PH: require("../../../assets/airlines/PH.png"),
  PI: require("../../../assets/airlines/PI.png"),
  PJ: require("../../../assets/airlines/PJ.png"),
  PK: require("../../../assets/airlines/PK.png"),
  PL: require("../../../assets/airlines/PL.png"),
  PM: require("../../../assets/airlines/PM.png"),
  PN: require("../../../assets/airlines/PN.png"),
  PO: require("../../../assets/airlines/PO.png"),
  PQ: require("../../../assets/airlines/PQ.png"),
  PR: require("../../../assets/airlines/PR.png"),
  PS: require("../../../assets/airlines/PS.png"),
  PT: require("../../../assets/airlines/PT.png"),
  PU: require("../../../assets/airlines/PU.png"),
  PV: require("../../../assets/airlines/PV.png"),
  PW: require("../../../assets/airlines/PW.png"),
  PX: require("../../../assets/airlines/PX.png"),
  PY: require("../../../assets/airlines/PY.png"),
  PZ: require("../../../assets/airlines/PZ.png"),
  Q2: require("../../../assets/airlines/Q2.png"),
  Q3: require("../../../assets/airlines/Q3.png"),
  Q4: require("../../../assets/airlines/Q4.png"),
  Q5: require("../../../assets/airlines/Q5.png"),
  Q6: require("../../../assets/airlines/Q6.png"),
  Q7: require("../../../assets/airlines/Q7.png"),
  Q8: require("../../../assets/airlines/Q8.png"),
  Q9: require("../../../assets/airlines/Q9.png"),
  QA: require("../../../assets/airlines/QA.png"),
  QB: require("../../../assets/airlines/QB.png"),
  QC: require("../../../assets/airlines/QC.png"),
  QE: require("../../../assets/airlines/QE.png"),
  QF: require("../../../assets/airlines/QF.png"),
  QG: require("../../../assets/airlines/QG.png"),
  QH: require("../../../assets/airlines/QH.png"),
  QI: require("../../../assets/airlines/QI.png"),
  QK: require("../../../assets/airlines/QK.png"),
  QL: require("../../../assets/airlines/QL.png"),
  QM: require("../../../assets/airlines/QM.png"),
  QN: require("../../../assets/airlines/QN.png"),
  QO: require("../../../assets/airlines/QO.png"),
  QP: require("../../../assets/airlines/QP.png"),
  QQ: require("../../../assets/airlines/QQ.png"),
  QR: require("../../../assets/airlines/QR.png"),
  QS: require("../../../assets/airlines/QS.png"),
  QT: require("../../../assets/airlines/QT.png"),
  QU: require("../../../assets/airlines/QU.png"),
  QV: require("../../../assets/airlines/QV.png"),
  QW: require("../../../assets/airlines/QW.png"),
  QX: require("../../../assets/airlines/QX.png"),
  QY: require("../../../assets/airlines/QY.png"),
  QZ: require("../../../assets/airlines/QZ.png"),
  R2: require("../../../assets/airlines/R2.png"),
  R3: require("../../../assets/airlines/R3.png"),
  R4: require("../../../assets/airlines/R4.png"),
  R5: require("../../../assets/airlines/R5.png"),
  R6: require("../../../assets/airlines/R6.png"),
  R7: require("../../../assets/airlines/R7.png"),
  R9: require("../../../assets/airlines/R9.png"),
  RA: require("../../../assets/airlines/RA.png"),
  RB: require("../../../assets/airlines/RB.png"),
  RC: require("../../../assets/airlines/RC.png"),
  RD: require("../../../assets/airlines/RD.png"),
  RF: require("../../../assets/airlines/RF.png"),
  RG: require("../../../assets/airlines/RG.png"),
  RH: require("../../../assets/airlines/RH.png"),
  RI: require("../../../assets/airlines/RI.png"),
  RJ: require("../../../assets/airlines/RJ.png"),
  RK: require("../../../assets/airlines/RK.png"),
  RM: require("../../../assets/airlines/RM.png"),
  RN: require("../../../assets/airlines/RN.png"),
  RO: require("../../../assets/airlines/RO.png"),
  RQ: require("../../../assets/airlines/RQ.png"),
  RR: require("../../../assets/airlines/RR.png"),
  RS: require("../../../assets/airlines/RS.png"),
  RT: require("../../../assets/airlines/RT.png"),
  RU: require("../../../assets/airlines/RU.png"),
  RV: require("../../../assets/airlines/RV.png"),
  RW: require("../../../assets/airlines/RW.png"),
  RY: require("../../../assets/airlines/RY.png"),
  RZ: require("../../../assets/airlines/RZ.png"),
  S0: require("../../../assets/airlines/S0.png"),
  S2: require("../../../assets/airlines/S2.png"),
  S4: require("../../../assets/airlines/S4.png"),
  S5: require("../../../assets/airlines/S5.png"),
  S6: require("../../../assets/airlines/S6.png"),
  S7: require("../../../assets/airlines/S7.png"),
  S8: require("../../../assets/airlines/S8.png"),
  S9: require("../../../assets/airlines/S9.png"),
  SA: require("../../../assets/airlines/SA.png"),
  SB: require("../../../assets/airlines/SB.png"),
  SC: require("../../../assets/airlines/SC.png"),
  SD: require("../../../assets/airlines/SD.png"),
  SE: require("../../../assets/airlines/SE.png"),
  SF: require("../../../assets/airlines/SF.png"),
  SG: require("../../../assets/airlines/SG.png"),
  SH: require("../../../assets/airlines/SH.png"),
  SI: require("../../../assets/airlines/SI.png"),
  SJ: require("../../../assets/airlines/SJ.png"),
  SK: require("../../../assets/airlines/SK.png"),
  SL: require("../../../assets/airlines/SL.png"),
  SM: require("../../../assets/airlines/SM.png"),
  SN: require("../../../assets/airlines/SN.png"),
  SO: require("../../../assets/airlines/SO.png"),
  SP: require("../../../assets/airlines/SP.png"),
  SQ: require("../../../assets/airlines/SQ.png"),
  SR: require("../../../assets/airlines/SR.png"),
  SS: require("../../../assets/airlines/SS.png"),
  ST: require("../../../assets/airlines/ST.png"),
  SU: require("../../../assets/airlines/SU.png"),
  SV: require("../../../assets/airlines/SV.png"),
  SY: require("../../../assets/airlines/SY.png"),
  SZ: require("../../../assets/airlines/SZ.png"),
  T2: require("../../../assets/airlines/T2.png"),
  T3: require("../../../assets/airlines/T3.png"),
  T5: require("../../../assets/airlines/T5.png"),
  T6: require("../../../assets/airlines/T6.png"),
  T7: require("../../../assets/airlines/T7.png"),
  T9: require("../../../assets/airlines/T9.png"),
  TA: require("../../../assets/airlines/TA.png"),
  TB: require("../../../assets/airlines/TB.png"),
  TC: require("../../../assets/airlines/TC.png"),
  TD: require("../../../assets/airlines/TD.png"),
  TE: require("../../../assets/airlines/TE.png"),
  TF: require("../../../assets/airlines/TF.png"),
  TG: require("../../../assets/airlines/TG.png"),
  TH: require("../../../assets/airlines/TH.png"),
  TI: require("../../../assets/airlines/TI.png"),
  TJ: require("../../../assets/airlines/TJ.png"),
  TK: require("../../../assets/airlines/TK.png"),
  TL: require("../../../assets/airlines/TL.png"),
  TM: require("../../../assets/airlines/TM.png"),
  TN: require("../../../assets/airlines/TN.png"),
  TO: require("../../../assets/airlines/TO.png"),
  TP: require("../../../assets/airlines/TP.png"),
  TR: require("../../../assets/airlines/TR.png"),
  TS: require("../../../assets/airlines/TS.png"),
  TT: require("../../../assets/airlines/TT.png"),
  TU: require("../../../assets/airlines/TU.png"),
  TV: require("../../../assets/airlines/TV.png"),
  TW: require("../../../assets/airlines/TW.png"),
  TX: require("../../../assets/airlines/TX.png"),
  TY: require("../../../assets/airlines/TY.png"),
  TZ: require("../../../assets/airlines/TZ.png"),
  U2: require("../../../assets/airlines/U2.png"),
  U3: require("../../../assets/airlines/U3.png"),
  U4: require("../../../assets/airlines/U4.png"),
  U5: require("../../../assets/airlines/U5.png"),
  U6: require("../../../assets/airlines/U6.png"),
  U7: require("../../../assets/airlines/U7.png"),
  U8: require("../../../assets/airlines/U8.png"),
  UA: require("../../../assets/airlines/UA.png"),
  UB: require("../../../assets/airlines/UB.png"),
  UC: require("../../../assets/airlines/UC.png"),
  UD: require("../../../assets/airlines/UD.png"),
  UF: require("../../../assets/airlines/UF.png"),
  UG: require("../../../assets/airlines/UG.png"),
  UI: require("../../../assets/airlines/UI.png"),
  UJ: require("../../../assets/airlines/UJ.png"),
  UK: require("../../../assets/airlines/UK.png"),
  UL: require("../../../assets/airlines/UL.png"),
  UM: require("../../../assets/airlines/UM.png"),
  UN: require("../../../assets/airlines/UN.png"),
  UO: require("../../../assets/airlines/UO.png"),
  UP: require("../../../assets/airlines/UP.png"),
  UQ: require("../../../assets/airlines/UQ.png"),
  UR: require("../../../assets/airlines/UR.png"),
  US: require("../../../assets/airlines/US.png"),
  UT: require("../../../assets/airlines/UT.png"),
  UU: require("../../../assets/airlines/UU.png"),
  UX: require("../../../assets/airlines/UX.png"),
  UZ: require("../../../assets/airlines/UZ.png"),
  V0: require("../../../assets/airlines/V0.png"),
  V2: require("../../../assets/airlines/V2.png"),
  V3: require("../../../assets/airlines/V3.png"),
  V4: require("../../../assets/airlines/V4.png"),
  V5: require("../../../assets/airlines/V5.png"),
  V6: require("../../../assets/airlines/V6.png"),
  V7: require("../../../assets/airlines/V7.png"),
  V8: require("../../../assets/airlines/V8.png"),
  V9: require("../../../assets/airlines/V9.png"),
  VA: require("../../../assets/airlines/VA.png"),
  VB: require("../../../assets/airlines/VB.png"),
  VC: require("../../../assets/airlines/VC.png"),
  VE: require("../../../assets/airlines/VE.png"),
  VF: require("../../../assets/airlines/VF.png"),
  VI: require("../../../assets/airlines/VI.png"),
  VJ: require("../../../assets/airlines/VJ.png"),
  VK: require("../../../assets/airlines/VK.png"),
  VL: require("../../../assets/airlines/VL.png"),
  VM: require("../../../assets/airlines/VM.png"),
  VN: require("../../../assets/airlines/VN.png"),
  VP: require("../../../assets/airlines/VP.png"),
  VQ: require("../../../assets/airlines/VQ.png"),
  VR: require("../../../assets/airlines/VR.png"),
  VS: require("../../../assets/airlines/VS.png"),
  VT: require("../../../assets/airlines/VT.png"),
  VU: require("../../../assets/airlines/VU.png"),
  VV: require("../../../assets/airlines/VV.png"),
  VY: require("../../../assets/airlines/VY.png"),
  VZ: require("../../../assets/airlines/VZ.png"),
  W2: require("../../../assets/airlines/W2.png"),
  W3: require("../../../assets/airlines/W3.png"),
  W4: require("../../../assets/airlines/W4.png"),
  W5: require("../../../assets/airlines/W5.png"),
  W6: require("../../../assets/airlines/W6.png"),
  W8: require("../../../assets/airlines/W8.png"),
  W9: require("../../../assets/airlines/W9.png"),
  WA: require("../../../assets/airlines/WA.png"),
  WB: require("../../../assets/airlines/WB.png"),
  WD: require("../../../assets/airlines/WD.png"),
  WF: require("../../../assets/airlines/WF.png"),
  WG: require("../../../assets/airlines/WG.png"),
  WH: require("../../../assets/airlines/WH.png"),
  WI: require("../../../assets/airlines/WI.png"),
  WJ: require("../../../assets/airlines/WJ.png"),
  WK: require("../../../assets/airlines/WK.png"),
  WL: require("../../../assets/airlines/WL.png"),
  WM: require("../../../assets/airlines/WM.png"),
  WN: require("../../../assets/airlines/WN.png"),
  WP: require("../../../assets/airlines/WP.png"),
  WR: require("../../../assets/airlines/WR.png"),
  WS: require("../../../assets/airlines/WS.png"),
  WT: require("../../../assets/airlines/WT.png"),
  WU: require("../../../assets/airlines/WU.png"),
  WV: require("../../../assets/airlines/WV.png"),
  WW: require("../../../assets/airlines/WW.png"),
  WX: require("../../../assets/airlines/WX.png"),
  WY: require("../../../assets/airlines/WY.png"),
  WZ: require("../../../assets/airlines/WZ.png"),
  X3: require("../../../assets/airlines/X3.png"),
  X4: require("../../../assets/airlines/X4.png"),
  X5: require("../../../assets/airlines/X5.png"),
  X6: require("../../../assets/airlines/X6.png"),
  X7: require("../../../assets/airlines/X7.png"),
  X9: require("../../../assets/airlines/X9.png"),
  XC: require("../../../assets/airlines/XC.png"),
  XE: require("../../../assets/airlines/XE.png"),
  XF: require("../../../assets/airlines/XF.png"),
  XJ: require("../../../assets/airlines/XJ.png"),
  XK: require("../../../assets/airlines/XK.png"),
  XL: require("../../../assets/airlines/XL.png"),
  XM: require("../../../assets/airlines/XM.png"),
  XN: require("../../../assets/airlines/XN.png"),
  XO: require("../../../assets/airlines/XO.png"),
  XP: require("../../../assets/airlines/XP.png"),
  XQ: require("../../../assets/airlines/XQ.png"),
  XR: require("../../../assets/airlines/XR.png"),
  XU: require("../../../assets/airlines/XU.png"),
  XY: require("../../../assets/airlines/XY.png"),
  XZ: require("../../../assets/airlines/XZ.png"),
  Y2: require("../../../assets/airlines/Y2.png"),
  Y3: require("../../../assets/airlines/Y3.png"),
  Y4: require("../../../assets/airlines/Y4.png"),
  Y5: require("../../../assets/airlines/Y5.png"),
  Y7: require("../../../assets/airlines/Y7.png"),
  Y8: require("../../../assets/airlines/Y8.png"),
  YB: require("../../../assets/airlines/YB.png"),
  YC: require("../../../assets/airlines/YC.png"),
  YE: require("../../../assets/airlines/YE.png"),
  YG: require("../../../assets/airlines/YG.png"),
  YH: require("../../../assets/airlines/YH.png"),
  YI: require("../../../assets/airlines/YI.png"),
  YK: require("../../../assets/airlines/YK.png"),
  YL: require("../../../assets/airlines/YL.png"),
  YN: require("../../../assets/airlines/YN.png"),
  YO: require("../../../assets/airlines/YO.png"),
  YP: require("../../../assets/airlines/YP.png"),
  YQ: require("../../../assets/airlines/YQ.png"),
  YR: require("../../../assets/airlines/YR.png"),
  YS: require("../../../assets/airlines/YS.png"),
  YT: require("../../../assets/airlines/YT.png"),
  YU: require("../../../assets/airlines/YU.png"),
  YV: require("../../../assets/airlines/YV.png"),
  YW: require("../../../assets/airlines/YW.png"),
  YX: require("../../../assets/airlines/YX.png"),
  Z0: require("../../../assets/airlines/Z0.png"),
  Z2: require("../../../assets/airlines/Z2.png"),
  Z5: require("../../../assets/airlines/Z5.png"),
  Z7: require("../../../assets/airlines/Z7.png"),
  ZA: require("../../../assets/airlines/ZA.png"),
  ZB: require("../../../assets/airlines/ZB.png"),
  ZD: require("../../../assets/airlines/ZD.png"),
  ZE: require("../../../assets/airlines/ZE.png"),
  ZF: require("../../../assets/airlines/ZF.png"),
  ZG: require("../../../assets/airlines/ZG.png"),
  ZH: require("../../../assets/airlines/ZH.png"),
  ZK: require("../../../assets/airlines/ZK.png"),
  ZL: require("../../../assets/airlines/ZL.png"),
  ZN: require("../../../assets/airlines/ZN.png"),
  ZO: require("../../../assets/airlines/ZO.png"),
  ZP: require("../../../assets/airlines/ZP.png"),
  ZQ: require("../../../assets/airlines/ZQ.png"),
  ZR: require("../../../assets/airlines/ZR.png"),
  ZT: require("../../../assets/airlines/ZT.png"),
  ZV: require("../../../assets/airlines/ZV.png"),
  ZW: require("../../../assets/airlines/ZW.png"),
  ZX: require("../../../assets/airlines/ZX.png"),
  ZY: require("../../../assets/airlines/ZY.png"),
};

export default logos;
