import React from "react";
import "../Landing.css";
import {convertToIST, customAirlinesName} from "../utils";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // Import the utc plugin
dayjs.extend(utc); // Extend dayjs with the utc plugin



const SingleFlightDeparture = ({ flight, columns, index }) => {

  const getValue = (item) => {
    let value = "";
    if (item?.type === "time") {
      value = convertToIST(flight[item.key]);
    }else if (item?.type === "destination") {
      value = flight?.destination?.city
      return <p className="flight-item-location-text">{value}</p>;
    }else if (item?.type === "airline") {
      try{
        return (
          <div className="first-column">
            <p
              className="flight-item-location-text"
              style={{ textAlign: "left", paddingLeft: "5px" }}
            >
              {customAirlinesName(flight[item.key])}
            </p>
          </div>
        );
      }catch(e){
        return '-'
      }
    }else if (item?.type === "gate") {
      return flight?.terminal_origin
    }else if (item?.type === "status"){
      value = flight[item.key]
      let status = "";
      let colorCode = "#000000";
      if(flight?.actual_departure_gate){
        if(flight?.status?.includes("Taxiing")){
          status = "Departed";
        }else{
          status = flight?.status;
        }
        colorCode = "#4caf50"
      }else {
        // Get Difference
        const date1 = dayjs.utc(flight?.estimated_out);
        const date2 = dayjs.utc(flight?.scheduled_out);

        // Calculate the difference in milliseconds
        const differenceInMs = date2.diff(date1);
        if(differenceInMs > 0 || differenceInMs === 0){
          if(flight?.status === "Scheduled"){
            status = "On Time";
          }else if(flight?.status?.includes("Taxiing")){
            status = "Departed";
          }else{
            status = flight?.status;
          }
          colorCode = "#4caf50"
        }else{
          if(flight?.status === "Scheduled" || flight?.status?.includes("Delayed")){
            status = "Delayed";
          }else if(flight?.status?.includes("Taxiing")){
            status = "Departed";
          }else{
            status = flight?.status;
          }
          colorCode = "#E97451"
        }
      }

      return <p className="flight-item-status-text" style={{color: colorCode}}>{status}</p>
    }else{
      value = flight[item.key]
    }
    return value;
  };

  return (
    <div className="flight-item" style={{backgroundColor: index % 2 === 0 ? "#d9d9d9" : "#ffffff"}}>
      {columns?.map((item) => {
        return (
          <div key={item.key} className="column">
            {getValue(item)}
          </div>
        );
      })}
    </div>
  );
};

export default SingleFlightDeparture;
