import React, { useEffect, useState, useRef } from "react";
import "../Landing.css";
import useLanding from "../useLanding";
import DisplayTableHeader from "./DisplayTableHeader";
import { ArrivalHeaders, DepartureHeaders } from "../constants/Headers";
import SingleFlight from "./SingleFlight";
import CurrentTime from "./CurrentTime";
import SingleFlightDeparture from "./SingleFlightDeparture";
import DashboardLogoContainer from "./DashboardLogoContainer";
import loading from '../../../assets/spinner.gif';
const ROWS_PER_PAGE = 10;
const TIMER_INTERVAL_TO_CHANGE_DATA = 10000;

const DisplayCard = ({ category, title, image }) => {
  let columnsList = [];
  if (category === "arrivals") {
    columnsList = ArrivalHeaders;
  } else if (category === "departures") {
    columnsList = DepartureHeaders;
  }

  const { getData } = useLanding();
  const [flights, setFlights] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const flightsRef = useRef([]);
  flightsRef.current = flights;

  const [visibleFlights, setVisibleFlights] = useState([]);

  const showNextRoundData = (startIndex) => {
    if (startIndex >= flightsRef.current?.length) {
      startIndex = 0;
    }
    const endIndex = startIndex + ROWS_PER_PAGE;
    let newFlights = [];
    try {
      setActivePage(parseInt(startIndex) / 10 + 1);
      newFlights = flightsRef.current.slice(startIndex, endIndex);
    } catch (e) {
      console.log("error");
    }

    // Set the visible rows with a delay for animation
    if (newFlights?.length > 0) {
      setTimeout(() => setVisibleFlights(newFlights), 500);
    }
    setTimeout(() => {
      showNextRoundData(endIndex);
    }, TIMER_INTERVAL_TO_CHANGE_DATA);
  };

  useEffect(() => {
    fetchFlightData(true);
  }, []);

  const fetchFlightData = async (initial = false) => {
    let flightData = await getData(category);
    setFlights(flightData);
    if (initial) {
      setTimeout(() => {
        showNextRoundData(0);
      }, 1500);
    }
    setTimeout(() => {
      fetchFlightData();
    }, 60 * 1000);
  };

  return (
    <div className="display-card">
      <div className="card-title-view">
        <div className="card-details">
          <img src={image} className="card-logo" alt="logo-img" />
          <p className="title">{title}</p>
        </div>
        <div>
          <DashboardLogoContainer />
        </div>
        <div className="card-details" style={{ justifyContent: "flex-end" }}>
          <CurrentTime />
        </div>
      </div>
      <DisplayTableHeader columns={columnsList} />
      {category === "arrivals" ? (
        <>
          {visibleFlights?.map((item, index) => {
            return (
              <SingleFlight
                flight={item}
                key={item.id}
                columns={columnsList}
                index={index}
              />
            );
          })}
        </>
      ) : (
        <>
          {visibleFlights?.map((item, index) => {
            return (
              <SingleFlightDeparture
                flight={item}
                key={item.id}
                columns={columnsList}
                index={index}
              />
            );
          })}
        </>
      )}
      {visibleFlights?.length === 0 &&
      <div className="loading-div">
        <img src={loading} alt="loading"/>
        <p>Please wait while we are fetching data...</p>
      </div> } 
      {/* <div className="dashboard-footer">
        <p className="page-number">
          Powered by CorePeelers
        </p>
        <p className="page-number">
          Showing page {activePage} of {flights?.length / 10}
        </p>
      </div> */}
    </div>
  );
};

export default DisplayCard;
