import { useNavigate } from "react-router-dom";
import { requestCallGet } from "../../service-request/APIRequest";
const useLanding = () => {
  const navigate = useNavigate();
  // Get Hotel List
  const getData = async (status) => {
    const response = await requestCallGet(`/flight/${status}`);
    if (response.status && response.data?.data?.flight_details) {
      return response.data?.data?.flight_details;
    }else{
      navigate("/")
    }
  };

  return {
    getData,
  };
};

export default useLanding;
