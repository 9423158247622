import React from "react";
import "./Landing.css";
import DisplayCard from "./components/DisplayCard";
import ArrivalImage from "../../assets/arrival.png";
import DepartureImage from "../../assets/departure.png";
import Marquee from "./components/Marquee";
const App = () => {
  return (
    <div>
      <div className="landing-container">
        <div className="card-view">
          <DisplayCard
            category={"arrivals"}
            title={"ARRIVAL"}
            image={ArrivalImage}
          />
        </div>
        <div className="card-view">
          <DisplayCard
            category={"departures"}
            title={"DEPARTURE"}
            image={DepartureImage}
          />
        </div>
      </div>
      <Marquee/>
    </div>
  );
};

export default App;
