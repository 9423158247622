const ArrivalHeaders = [
    {
        key: 'airline_details',
        label:'AIRLINE',
        type:'airline', 
    },
    {
        key: 'ident_iata',
        label:'FLIGHT',
        type:'text', 
    },
    {
        key: 'origin',
        label:'ORIGIN',
        type:'origin', 
    },
    {
        key: 'scheduled_in',
        label:'STA',
        type:'time', 
    },
    {
        key: 'estimated_in',
        label:'ETA',
        type:'time', 
    },
    {
        key: 'terminal_destination',
        label:'TERMINAL',
        type:'gate', 
    },
    {
        key: 'status',
        label:'STATUS',
        type:'status', 
    },
]

const DepartureHeaders = [
    {
        key: 'airline_details',
        label:'AIRLINE',
        type:'airline', 
    },
    {
        key: 'ident_iata',
        label:'FLIGHT',
        type:'text', 
    },
    {
        key: 'origin?.city',
        label:'DESTINATION',
        type:'destination', 
    },
    {
        key: 'scheduled_out',
        label:'STD',
        type:'time', 
    },
    {
        key: 'estimated_out',
        label:'ETD',
        type:'time', 
    },
    {
        key: 'terminal_origin',
        label:'TERMINAL',
        type:'gate', 
    },
    {
        key: 'status',
        label:'STATUS',
        type:'status', 
    },
]

export {
    ArrivalHeaders,
    DepartureHeaders
}