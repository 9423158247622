import { useState } from "react";
import { requestCallGet } from "../../service-request/APIRequest";
import { useNavigate } from "react-router-dom";

const SETTINGS_PARAMS = {
  license_key:''
}
const useSettings = () => {
  const navigate = useNavigate();
  const [settingsParam, setSettingsParam] = useState(SETTINGS_PARAMS)
  const [settingsParamError, setSettingsParamError] = useState(null);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const handleSettingsParam = (name, value) =>{
    if(settingsParamError !== null){
      setSettingsParamError(null)
    }
    setSettingsParam({...settingsParam, [name]:value})
  }

  const validateLicenseKey = async () => {
    if(settingsParam?.license_key?.trim() === ""){
      setSettingsParamError({license_key: "Please enter your license key."})
      return 
    }
    setVerificationLoading(true)
    let headers = {
      "Flight-API-Token": settingsParam?.license_key,
      "Session-ID": localStorage.getItem("current_session_id"),
    };

    const response = await requestCallGet(`/flight/arrivals`, {}, headers);
    setVerificationLoading(false)
    if (response.status && response.data?.data?.flight_details) {
      localStorage.setItem("flight-token", settingsParam?.license_key)
      navigate('/dashboard');
    }else{
      setSettingsParamError({license_key: "Invalid license key."})
      return
    }
  };

  return {
    settingsParam,
    settingsParamError,
    validateLicenseKey,
    handleSettingsParam,
    verificationLoading
  };
};

export default useSettings;
