import React from "react";
function DashboardLogoContainer() {
  return (
    <div className="dashboard-logo-container">
      {/* <img
        src={localStorage.getItem("logo_path")}
        className="dashboard-logo"
        alt="dashboard-logo"
      /> */}
      <div>
        <p className="dashboard-logo-text" style={{ color: "#ffffff" }}>
          {/* {localStorage.getItem("hotel_name")} */}
          Flight Information <br />
          Display System
        </p>
        <p className="dashboard-logo-text" style={{ color: "#ffffff" }}>
          {/* {localStorage.getItem("hotel_address")} */}
        </p>
      </div>
    </div>
  );
}

export default DashboardLogoContainer;
