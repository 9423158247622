import React from "react";
import "./Settings.style.css";
import CustomInputField from "../../common/CustomInputField";
import useSettings from "./useSettings";
import { Row, Col } from "react-bootstrap";
import PrimaryButton from "../../common/PrimaryButton";
import { useNavigate } from "react-router-dom";
import wave from "../../assets/wave.png";
import Footer from "../../common/Footer";
const Settings = () => {
  const navigate = useNavigate();
  const {
    settingsParam,
    settingsParamError,
    validateLicenseKey,
    handleSettingsParam,
    verificationLoading
  } = useSettings();

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <div className="settings-container">
        <div className="settings-header">
          <div className="header-logo-container">
            <img
              src={localStorage.getItem("logo_path")}
              alt="airplane_image"
              className="logo-img"
            />
            <p>{localStorage.getItem("hotel_address")}</p>
          </div>
        </div>
        <Row style={{ margin: 0, padding: "15px 0px" }}>
          <Col></Col>
          <Col>
            <div className="welcome-div">
              <img src={wave} alt="wave" className="hi-img" />
              <p className="hi-text">Hi {localStorage.getItem("name")}</p>
              <p>
                Welcome to our Flight Information Display System. Please enter below the License Key provided to you in your registered email id to verify your device registration.
              </p>
              <div style={{ width:"100%", marginTop:"30px" }}>
                <CustomInputField
                  label={"License Key"}
                  required
                  error={settingsParamError?.license_key}
                  name="license_key"
                  type={"text"}
                  placeholder="License Key"
                  value={settingsParam.license_key}
                  onChange={(e) =>
                    handleSettingsParam("license_key", e.target.value)
                  }
                />
                <Row>
                  <Col>
                    <PrimaryButton
                      label="Submit"
                      onClick={() => validateLicenseKey()}
                      loading={verificationLoading}
                    />
                  </Col>
                  <Col>
                    <PrimaryButton label="Logout" onClick={() => logout()} />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Settings;
