import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "./common.css"
const CustomInputField = ({
  label,
  type = "text",
  name,
  placeholder = "Write...",
  value,
  onChange,
  error,
  disabled = false,
  required = false,
  accept = "",
  maxLength 
}) => {
  const [currentType, setCurrentType] = useState(type);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Form.Group
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {label && (
          <span>
            {label} {required && <span style={{ color: "#ff0000" }}>*</span>}
          </span>
        )}
        <div style={{display:"flex", width:"100%", position:"relative"}}>
          <Form.Control
            type={currentType}
            placeholder={placeholder}
            value={value}
            name={name}
            accept={accept}
            className={disabled ? "h-39px" : "bg-white h-39px"}
            onChange={onChange}
            isInvalid={error}
            style={{ backgroundColor: disabled ? "#e6e6e6" : "transparent" }}
            disabled={disabled}
            maxLength={maxLength}
          />
          {type === "password" && <button className="password-visible" onClick={() => setCurrentType(currentType === "password" ? "text" : "password")}>{currentType === "password" ? <i className="fa-solid fa-eye"></i> : <i class="fa-solid fa-eye-slash"></i>}</button>}
        </div>
        {error && (
          <Form.Control.Feedback
            type="invalid"
            style={{ fontSize: "10px", marginLeft: "4px", textAlign: "left", display:"block" }}
          >
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </div>
  );
};

export default CustomInputField;
