import React, { useState, useEffect } from 'react';

const CurrentDateTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000 * 60); // Update every second

    return () => clearInterval(timer);
  }, []);

  // Format the time in 24-hour format
  const formatTime = (date) => {
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  return (
    <div style={{display:'flex', flexDirection:"column", alignItems:"flex-end"}}>
      <p className="current-date">{currentTime.toLocaleDateString()}</p>
      <p className="current-time-text">{formatTime(currentTime)}</p>
    </div>
  );
};

export default CurrentDateTime;
