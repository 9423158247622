import React from "react";
import "../Landing.css";

const DisplayTableHeader = ({ columns }) => {
  return (
    <div className="display-header-container">
      {columns?.map((item, index) => {
        return (
          <div
            key={item.key}
            className="column header"
            style={{ flex: index === 4 ? 1.5 : 1 }}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default DisplayTableHeader;
