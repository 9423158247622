import React from "react";
import "./Auth.style.css";
import useAuth from "./useAuth";
import { Row, Col, Alert } from "react-bootstrap";
import logo from "../../assets/aeroglance_logo.png";
import CustomInputField from "../../common/CustomInputField";
import PrimaryButton from "../../common/PrimaryButton";
import Footer from "../../common/Footer";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    forgotPasswordFunc,
    forgotPassword, 
    setForgotPassword,
    forgotPasswordStatus,
    forgotPasswordLoading
  } = useAuth("forgotpassword");

  return (
    <div>
      <div className="signup-page">
        <div className="signup-container">
          <div className="login-card">
            <div className="signup-form-container">
              <img src={logo} alt="logo" className="logo-img" />
              {forgotPasswordStatus?.status && (
                <Row>
                  <Col>
                    <Alert variant={forgotPasswordStatus?.status}>
                      {forgotPasswordStatus?.message}
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row style={{ margin: 0 }}>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Registered Email"}
                    required
                    name="email"
                    type={"text"}
                    placeholder="Email"
                    value={forgotPassword}
                    onChange={(e) =>
                      setForgotPassword(e.target.value)
                    }
                  />
                </Col>
              </Row>
              <p style={{fontSize:"10px"}}>Please enter your registered email. We'll send you a link for password reset.</p>
              <div style={{ height: "10px" }} />
              <Row>
                <Col>
                  <PrimaryButton
                    label="SEND EMAIL"
                    onClick={() => forgotPasswordFunc("login")}
                    loading={forgotPasswordLoading}
                  />
                </Col>
              </Row>
              <div style={{ height: "30px" }} />
              <div>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                >
                  Back to Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
