export const validateEmail = (mail) => {
  if (
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
};

export const validateMobile = (mobile) => {
  return /^(\+\d{1,3}[- ]?)?\d{10}$/.test(mobile);
};

export const isValidGSTIN = (gstin) => {
  // Regular expression for validating GSTIN:
  // ^ : Start of the string
  // \d{2} : First two digits should be numbers (state code)
  // [A-Z]{5} : Next 5 characters should be uppercase letters (PAN alphabets)
  // \d{4} : Next 4 characters should be numbers (PAN digits)
  // [A-Z]{1} : Next character should be an uppercase letter (PAN checksum character)
  // \d{1} : Entity number should be a digit
  // Z{1} : Default character should be 'Z'
  // [0-9A-Z]{1} : Checksum character (can be a number or letter)
  const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z{1}[0-9A-Z]{1}$/;

  return gstinRegex.test(gstin);
}


export const validatePassword = (password) => {
  // Regular expression:
  // (?=.*[0-9])    : Ensures at least one digit (0-9)
  // (?=.*[!@#$%^&*]): Ensures at least one special character (!@#$%^&*)
  // (?=.{8,})      : Ensures a minimum length of 8 characters
  const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  
  return passwordRegex.test(password);
}