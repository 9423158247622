const ArrivalHeaders = [
  {
    key: "scheduled_in",
    label: "STA",
    type: "time",
  },
  {
    key: "estimated_in",
    label: "ETA",
    type: "time",
  },
  {
    key: "airline_details",
    label: "AIRLINE",
    type: "airline",
  },
  {
    key: "ident_iata",
    label: "FLIGHT",
    type: "text",
  },
  {
    key: "origin",
    label: "FROM/VIA",
    type: "origin",
  },
  {
    key: "terminal_destination",
    label: "TERMINAL",
    type: "gate",
  },
  {
    key: "status",
    label: "STATUS",
    type: "status",
  },
];

const DepartureHeaders = [
  {
    key: "scheduled_out",
    label: "STD",
    type: "time",
  },
  {
    key: "estimated_out",
    label: "ETD",
    type: "time",
  },
  {
    key: "airline_details",
    label: "AIRLINE",
    type: "airline",
  },
  {
    key: "ident_iata",
    label: "FLIGHT",
    type: "text",
  },
  {
    key: "origin?.city",
    label: "TO/VIA",
    type: "destination",
  },
  {
    key: "terminal_origin",
    label: "TERMINAL",
    type: "gate",
  },
  {
    key: "status",
    label: "STATUS",
    type: "status",
  },
];

export { ArrivalHeaders, DepartureHeaders };
