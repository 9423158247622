import React, {useEffect} from "react";
import "./Auth.style.css";
import useAuth from "./useAuth";
import { Row, Col, Alert } from "react-bootstrap";
import logo from "../../assets/aeroglance_logo.png";
import CustomInputField from "../../common/CustomInputField";
import PrimaryButton from "../../common/PrimaryButton";
import Footer from "../../common/Footer";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    handleResetParamChange,
    resetParams,
    resetPasswordFunc,
    reset,
    resetParamError,
    resetLoading
  } = useAuth("login");

  useEffect(() => {
    getHash()
  }, []);

  const getHash = async () => {
    const queryParams = new URLSearchParams(location.search);
    let hash = queryParams.get("hash");
    if(hash){
      handleResetParamChange("reset_password_key", hash)
    }else{
      navigate("/")
    }
    
  };

  return (
    <div>
      <div className="signup-page">
        {/* <div className="signup-logo-container">
          <img src={Logo} alt="airplane_image" className="logo-img" />
        </div> */}
        <div className="signup-container">
          <div className="login-card">
            <div className="signup-form-container">
              <img src={logo} alt="logo" className="logo-img" />
              {reset?.status && (
                <Row>
                  <Col>
                    <Alert variant={reset?.status}>
                      {reset?.message}
                    </Alert>
                  </Col>
                </Row>
              )}
             <Row style={{ margin: 0 }}>
                <Col className="p-5px">
                  <CustomInputField
                    label={"New Password"}
                    required
                    error={resetParamError?.password}
                    name="password"
                    type={"password"}
                    placeholder="New Password"
                    value={resetParams.password}
                    onChange={(e) =>
                      handleResetParamChange("password", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row style={{ margin: 0 }}>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Confirm Password"}
                    required
                    error={resetParamError?.confirm_password}
                    name="confirm_password"
                    type={"password"}
                    placeholder="Confirm Password"
                    value={resetParams.confirm_password}
                    onChange={(e) =>
                      handleResetParamChange("confirm_password", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <div style={{ height: "10px" }} />
              <Row>
                <Col>
                  <PrimaryButton
                    label="UPDATE"
                    onClick={() => resetPasswordFunc()}
                    loading={resetLoading}
                  />
                </Col>
              </Row>
              <div style={{ height: "30px" }} />
              <div>
                <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/")
                }}
              >
                Back to Login
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
