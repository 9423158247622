import React, { useEffect, useState } from "react";
import "./Auth.style.css";
import useAuth from "./useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/aeroglance_logo.png";
import Footer from "../../common/Footer";
const ValidateEmail = () => {
  const { verifyEmail } = useAuth("validate_email");
  const [status, setStatus] = useState("Verifying email...");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      generateDeviceId();
    }, 1000);
  }, []);

  const generateDeviceId = async () => {
    const queryParams = new URLSearchParams(location.search);
    let code = queryParams.get("code");
    let id = queryParams.get("user");
    if (!code || !id) {
      setStatus("Invalid link.");
    } else {
      let params = {
        email_validation_key: code,
        id: id,
      };
      let status = await verifyEmail(params);
      if (status?.status === false) {
        setStatus(status?.status_msg);
      } else if (status) {
        setStatus("Email successfully verified. Please login to continue.");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setStatus("Invalid link.");
      }
    }
  };

  return (
    <div>
      <div className="validate-email-page">
        <div className="signup-container">
          <div className="signup-card">
            <div className="signup-form-container">
              <img src={logo} alt="logo" className="logo-img" />
              <p className="verification-status-text">{status}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ValidateEmail;
