import React from "react";
import "../Landing.css";
const Marquee = () => {
  return (
    <div className="marquee">
      <div className="marquee-content">
        <p>
          Disclaimer: Service Provider will not be responsible for the accuracy
          or timeliness of displayed information as these are sourced from third
          party providers. Users are requested to verify flight details with
          airlines.
        </p>
        <p>
          {'                                                                  '}
        </p>
      </div>
    </div>
  );
};

export default Marquee;
