import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Form from 'react-bootstrap/Form';
const CustomSingleSelectField = ({
  name,
  options = [],
  onChange,
  value,
  setKeyValue = {
    label: "label",
    value: "value",
  },
  isError = "",
  placeholder = "Select",
  label,
  required = false,
}) => {
  const animatedComponents = makeAnimated();
  const getOptionLabel = (option) => option[setKeyValue.label];
  const getOptionValue = (option) => option[setKeyValue.value];
  const [selectedValue, setSelectedValue] = useState({})

  useEffect(()=>{
    let selected_opt = options?.filter((item) => item[setKeyValue.value] === value)
    if(selected_opt?.length > 0){
      setSelectedValue(selected_opt[0])
    }else{
      setSelectedValue({value:"", label:"-Select-"})
    }
  }, [value, options?.length])

  return (
    <div>
      <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
      {label && <span>{label} {required && <span style={{color:"#ff0000"}}>*</span>}</span>}    
      </div>
      <Select
        isMulti={false}
        name={name}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={options}
        components={animatedComponents}
        className="basic-multi-select text-xs h-25"
        classNamePrefix="select font-13px input"
        classNames={'1111111'}
        closeMenuOnScroll={'22222'}
        value={selectedValue}
        onChange={onChange}
        placeholder={placeholder ? placeholder : "Select"}
        styles={{
          control: (provided) => ({
            ...provided,
            height: '30px !important', // Set input field height to 30px
            // fontSize: '16px !important'
          }),
          option: (provided) => ({
            ...provided,
            fontSize: '10px', // Set font size for dropdown options to 12px
          }),
        }}
      />
      {isError.length > 0 && (
        <div style={{display:"flex"}}>
        <span
          style={{
            fontSize: "12px",
            color: "red",
            marginLeft: "2px",
            textAlign:"left"
          }}
        >
          {isError}
        </span>
        </div>
      )}
    </div>
  );
};

export default CustomSingleSelectField;
