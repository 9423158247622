import axios from 'axios';
import { baseURLPath } from '../config/baseURLPath';
// import Constants from './Constants';
const instance =  axios.create({
  baseURL: baseURLPath,
  responseType: 'json',
  timeout: 60000,
  headers: {
    langId: 1,
    // 'access-token': Constants.AuthorizationKey,
    Accept: 'application/json',
  },
});

export const handleError = ({ message, data, status })=>{
  return Promise.reject({message,data,status});
}
instance.interceptors.response.use(
  (response) => response,
  ({message, response: {data, status}}) => {
    return handleError({message, data, status});
  },
);

export default instance;
