import React from 'react';
import "./common.css"
import spinner from '../assets/spinner.gif';
const PrimaryButton = (props) =>{
    return(
        <button disabled={props.loading} onClick={props.onClick} className='new-primary-button'>
            {props.loading ? <img src={spinner} alt="spinner" className='spinner' /> : props.label }
        </button>
    )
}

export default PrimaryButton;