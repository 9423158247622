import React from "react";
import "./Auth.style.css";
import useAuth from "./useAuth";
import { Row, Col, Alert } from "react-bootstrap";
import logo from "../../assets/aeroglance_logo.png";
import CustomInputField from "../../common/CustomInputField";
import PrimaryButton from "../../common/PrimaryButton";
import Footer from "../../common/Footer";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const {
    forceLogin,
    customerLogin,
    loginParams,
    loginParamError,
    handleLoginParamChange,
    registration,
    loginLoading, 
    signupLoading
  } = useAuth("login");

  return (
    <div>
      <div className="signup-page">
        <div className="signup-container">
          <div className="login-card">
            <div className="signup-form-container">
              <img src={logo} alt="logo" className="logo-img" />
              <p style={{fontSize:"12px", textAlign:'center'}}>(Email and password is mandatory for signup also.)</p>
              {registration?.status && (
                <Row>
                  <Col>
                    <Alert variant={registration?.status}>
                      {registration?.message}
                      {registration?.code === 403 && (
                        <a
                          href="/"
                          style={{ color: "#0000ff" }}
                          onClick={(e) => {
                            e.preventDefault();
                            forceLogin();
                          }}
                        >
                          {" "}
                          Continue with this device.
                        </a>
                      )}
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row style={{ margin: 0 }}>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Email"}
                    required
                    error={loginParamError?.email}
                    name="email"
                    type={"text"}
                    placeholder="Email"
                    value={loginParams.email}
                    onChange={(e) =>
                      handleLoginParamChange("email", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row style={{ margin: 0 }}>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Password"}
                    required
                    error={loginParamError?.password}
                    name="password"
                    type={"password"}
                    placeholder="Password"
                    value={loginParams.password}
                    onChange={(e) =>
                      handleLoginParamChange("password", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <div style={{ height: "10px" }} />
              <Row>
                <Col>
                  <PrimaryButton
                    label="LOGIN"
                    onClick={() => customerLogin("login")}
                    loading={loginLoading}
                  />
                </Col>
                <Col>
                  <PrimaryButton
                    label="SIGNUP"
                    onClick={() => customerLogin("signup")}
                    loading={signupLoading}
                  />
                </Col>
              </Row>
              <div style={{ height: "30px" }} />
              <div>
                <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/forgot-password")
                }}
              >
                Forgot Password?
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
