import React, {useEffect} from 'react';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screens/Auth/Login";
import FlightLandingPage from "./screens/Landing";
import Settings from "./screens/Settings";
import Signup from "./screens/Auth/Signup";
import ValidateEmail from "./screens/Auth/ValidateEmail";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import ResetPassword from "./screens/Auth/ResetPassword";

function App() {
  
  useEffect(() => {
    // Set up the global error handler
    window.onerror = function (message, source, lineno, colno, error) {
      console.log(`Global error caught: ${message} at ${source}:${lineno}:${colno}`);
      // You can send this error to an external logging service
      return true; // Return true to prevent the default behavior (such as showing the error to the user)
    };

    window.addEventListener('unhandledrejection', function (event) {
      console.log(`Unhandled promise rejection: ${event.reason}`);
      // Optionally log this to an external service
      event.preventDefault(); // Prevent the default logging behavior
    });

    return () => {
      // Clean up event listeners when the component unmounts
      window.onerror = null;
      window.removeEventListener('unhandledrejection', () => {});
    };
  }, []);

  return (
    <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/validateEmail" element={<ValidateEmail />} />
          <Route path="/dashboard" element={<FlightLandingPage />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Routes>
    </Router>
  );
}

export default App;
