import React from "react";
import "./Auth.style.css";
import useAuth from "./useAuth";
import { Row, Col, Alert } from "react-bootstrap";
import logo from "../../assets/aeroglance_logo.png";
import CustomInputField from "../../common/CustomInputField";
import PrimaryButton from "../../common/PrimaryButton";
import CustomSingleSelectField from "../../common/CustomSingleSelectField";
import Footer from "../../common/Footer";

const Signup = () => {
  const {
    customerSignup,
    signupParams,
    signupParamError,
    handleSignupParamChange,
    airportList,
    registration,
    handleFileChange,
    signupLoading,
  } = useAuth("signup");


  return (
    <div>
      <div className="signup-page">
        <div className="signup-container">
          <div className="signup-card">
            <div className="signup-form-container">
              <img src={logo} alt="logo" className="logo-img" />
              {registration?.status && (
                <Row>
                  <Col>
                    <Alert variant={registration?.status}>
                      {registration?.message}
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="p-5px">
                  <CustomInputField
                    label={"First Name"}
                    required
                    error={signupParamError?.first_name}
                    name="first_name"
                    type={"text"}
                    placeholder="First Name"
                    value={signupParams.first_name}
                    onChange={(e) =>
                      handleSignupParamChange("first_name", e.target.value)
                    }
                  />
                </Col>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Last Name"}
                    required
                    error={signupParamError?.last_name}
                    name="last_name"
                    type={"text"}
                    placeholder="Last Name"
                    value={signupParams.last_name}
                    onChange={(e) =>
                      handleSignupParamChange("last_name", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Email Name"}
                    required
                    error={signupParamError?.email}
                    name="email"
                    type={"text"}
                    placeholder="Email"
                    value={signupParams.email}
                    onChange={(e) =>
                      handleSignupParamChange("email", e.target.value)
                    }
                    disabled
                  />
                </Col>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Phone Name"}
                    required
                    error={signupParamError?.mobile_number}
                    name="mobile_number"
                    type={"text"}
                    placeholder="Mobile"
                    value={signupParams.mobile_number}
                    onChange={(e) =>
                      handleSignupParamChange("mobile_number", e.target.value)
                    }
                    maxLength={10}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Company Name"}
                    required
                    error={signupParamError?.hotel_name}
                    name="hotel_name"
                    type={"text"}
                    placeholder="Company Name"
                    value={signupParams.hotel_name}
                    onChange={(e) =>
                      handleSignupParamChange("hotel_name", e.target.value)
                    }
                  />
                </Col>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Address"}
                    required
                    error={signupParamError?.hotel_address}
                    name="hotel_address"
                    type={"text"}
                    placeholder="Address"
                    value={signupParams.hotel_address}
                    onChange={(e) =>
                      handleSignupParamChange("hotel_address", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="p-5px">
                  <CustomInputField
                    label={"GSTN"}
                    required
                    error={signupParamError?.gst_number}
                    name="gst_number"
                    type={"text"}
                    placeholder="GSTN"
                    value={signupParams.gst_number}
                    onChange={(e) =>
                      handleSignupParamChange("gst_number", e.target.value)
                    }
                  />
                </Col>
                <Col className="p-5px">
                  <CustomSingleSelectField
                    label={"Airport"}
                    required
                    options={airportList}
                    setKeyValue={{
                      label: "airport_name",
                      value: "id",
                    }}
                    name={"airport_id"}
                    isError={signupParamError?.airport_id}
                    value={signupParams?.airport_id?.id}
                    onChange={(e) => {
                      handleSignupParamChange("airport_id", { id: e.id });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="p-5px">
                  <CustomInputField
                    label={"Logo"}
                    required
                    accept="image/*"
                    error={signupParamError?.original_file_name}
                    name="original_file_name"
                    type={"file"}
                    placeholder=""
                    onChange={handleFileChange}
                  />
                </Col>
                <Col className="p-5px"></Col>
              </Row>
              <div style={{ height: "30px" }} />
              <PrimaryButton label="UPDATE DETAILS" onClick={customerSignup} loading={signupLoading} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
