import API from "../utils/API";

export async function requestCallPost(apiName, data, currentSessionId = "") {

  let headers = {};
  if(currentSessionId !== ""){
    headers = {
      "Session-ID": currentSessionId,
    };
  }
  return await API.post(apiName, data, {
    headers: headers
  })
    .then((response) => {
      return {
        status: true,
        message: "",
        data: response.data,
      };
    })
    .catch((err) => {
      return {
        status: false,
        message: err,
        data: null,
      };
    });
}

export async function requestCallPut(apiName, data) {
  return await API.put(apiName, data, {
    headers: {
      "Authorization": "Bearer "+ localStorage.getItem("token"),
    },
  })
    .then((response) => {
      return {
        status: true,
        message: "",
        data: response.data,
      };
    })
    .catch((err) => {
      return {
        status: false,
        message: err.toString(),
        data: null,
      };
    });
}

// API request call, get method
export async function requestCallGet(apiName, body, customHeaders = {}) {
  let headers = {};
  if(apiName.includes('flight')){
    headers = {
      "Flight-API-Token": localStorage.getItem("flight-token"),
      "Session-ID": localStorage.getItem("current_session_id"),
    };
  }

  if(customHeaders['Flight-API-Token']){
    headers = customHeaders
  }
  
  return await API.get(apiName, {
    headers: headers,
    params: body,
  })
    .then((response) => {
      return {
        status: true,
        message: "",
        data: response.data,
      };
    })
    .catch((err) => {
      return {
        status: false,
        message: err,
        data: null,
      };
    });
}

export async function requestCallDelete(apiName, body) {
  return await API.delete(apiName, {
    headers: {
      "Authorization": "Bearer "+ localStorage.getItem("token"),
    },
    params: body,
  })
    .then((response) => {
      return {
        status: true,
        message: "",
        data: response.data,
      };
    })
    .catch((err) => {
      return {
        status: false,
        message: err,
        data: null,
      };
    });
}

export async function postData(apiName) {}
