// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container{
    background-color: #ffffff;
    
}

.settings-header{
    background-color: #d2de60;
    padding: 10px 20px;
}


.header-logo-container{
    display: flex;
    align-items: center;
}

.header-logo-container img.logo-img{
    height: 60px;
    width: 60px;
}

.header-logo-container p{
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    color: #ffffff;
    letter-spacing: 1px;
}

.settings-form{
    position: relative;
    padding: 15px;
    border: 1px solid #ccc;
    margin-top: 15px;
}

.form-title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-title > p{
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    color: #000000;
    letter-spacing: 1px;
}

.welcome-div{
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hi-img{
    height: 80px;
    width: 80px;
}

.hi-text{
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    color: #000000;
    letter-spacing: 1px;
    margin:15px 0px
}`, "",{"version":3,"sources":["webpack://./src/screens/Settings/Settings.style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;;AAE7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;;AAGA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB;AACJ","sourcesContent":[".settings-container{\n    background-color: #ffffff;\n    \n}\n\n.settings-header{\n    background-color: #d2de60;\n    padding: 10px 20px;\n}\n\n\n.header-logo-container{\n    display: flex;\n    align-items: center;\n}\n\n.header-logo-container img.logo-img{\n    height: 60px;\n    width: 60px;\n}\n\n.header-logo-container p{\n    font-size: 14px;\n    font-weight: bold;\n    margin-left: 10px;\n    color: #ffffff;\n    letter-spacing: 1px;\n}\n\n.settings-form{\n    position: relative;\n    padding: 15px;\n    border: 1px solid #ccc;\n    margin-top: 15px;\n}\n\n.form-title{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.form-title > p{\n    font-size: 14px;\n    font-weight: bold;\n    margin-left: 10px;\n    color: #000000;\n    letter-spacing: 1px;\n}\n\n.welcome-div{\n    background-color: #f5f5f5;\n    padding: 15px;\n    border-radius: 10px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.hi-img{\n    height: 80px;\n    width: 80px;\n}\n\n.hi-text{\n    font-size: 20px;\n    font-weight: bold;\n    margin-left: 10px;\n    color: #000000;\n    letter-spacing: 1px;\n    margin:15px 0px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
